import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-multi-date-picker';
import getUzLocale from './uzbek';

const CRangePicker = ({ onSelectDateRange, render }) => {
	const { t } = useTranslation();
	const uz = getUzLocale(t);

	const [values, setValues] = useState([]);

	const handleDateChange = newValues => {
		setValues(newValues);
		if (newValues.length === 2) {
			const [start, end] = newValues;
			onSelectDateRange({
				start_at_after: start?.format('YYYY-MM-DD'),
				start_at_before: end?.format('YYYY-MM-DD'),
			});
		}
	};

	return (
		<DatePicker
			className='bg-transparent'
			format='DD MMMM YYYY'
			placeholder=''
			value={values}
			onChange={handleDateChange}
			range
			offsetX={20}
			dateSeparator=' - '
			portal
			zIndex={1000}
			numberOfMonths={window.innerWidth > 500 ? 2 : 1}
			render={render}
			locale={uz} // use the custom Uzbek locale
		/>
	);
};

export default CRangePicker;
