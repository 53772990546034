import React from "react";
import { useTranslation } from "react-i18next";

const FormatDate = (props) => {
  const { t } = useTranslation();

  const dateTable = {
    0: t("months.january"),
    1: t("months.february"),
    2: t("months.march"),
    3: t("months.april"),
    4: t("months.may"),
    5: t("months.june"),
    6: t("months.july"),
    7: t("months.august"),
    8: t("months.september"),
    9: t("months.october"),
    10: t("months.november"),
    11: t("months.december"),
  };
  const formatTime = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    //  const month = date.toLocaleString(i18n.language, { month: "long" });
    const month = dateTable[date.getMonth()];
    const year = date.getFullYear();

    const ordinalSuffix = (n) => {
      const s = [
        t("weekdays.th"),
        t("weekdays.st"),
        t("weekdays.nd"),
        t("weekdays.rd"),
      ];
      const v = n % 100;
      return s[(v - 20) % 10] || s[v] || s[0];
    };

    const translatedMonth = t(`${month}`);

    return `${day}${ordinalSuffix(day)} ${translatedMonth} ${year}`;
  };

  return (
    <p
      {...props}
      className={`${props?.className} text-center font-semibold text-lg  flex flex-col gap-1  justify-center h-full `}
    >
      {/* {props?.match_number ? (
				<span className='!select-none text-lightGray text-base font-semibold '>
					{props?.match_number}
					{props?.match_number === 1
						? t('others.1st_match')
						: props?.match_number === 2
						? t('others.2nd_match')
						: props?.match_number === 3
						? t('others.3rd_match')
						: t('others.other_match')}
				</span>
			) : null} */}
      <span className="!select-none">{formatTime(props?.time)}</span>
    </p>
  );
};

export default FormatDate;
