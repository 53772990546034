import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import Layout from "../app/[locale]/layout";
import Home from "../app/[locale]/(root)/(home)/page";
import TicketSingle from "../app/[locale]/(root)/ticket/[slug]/page";
// import NewsSingle from "../app/[locale]/(root)/news/[slug]/page";
import NotFount from "../app/[locale]/(root)/page";
// import Congratulations from "../app/[locale]/(root)/congratulations/page";
import RefundTicket from "../app/[locale]/(root)/refundticket/page";
import Success from "../app/[locale]/(root)/success/[id]/page";

const Routers = () => {
  return (
    <Routes>
      <Route
        element={
          <Layout>
            <Outlet />
          </Layout>
        }
      >
        <Route path="/">
          <Route index element={<Home />} />
          <Route path="/ticket/">
            <Route path=":slug" element={<TicketSingle />} />
          </Route>
          <Route path="/success/">
            <Route path=":id" element={<Success />} />
          </Route>
          {/* <Route path="/news/">
            <Route path=":slug" element={<NewsSingle />} />
          </Route> */}
          {/* <Route path="/congratulations" element={<Congratulations />} /> */}
          <Route path="/refund" element={<RefundTicket />} />
        </Route>
      </Route>
      <Route path="*" element={<NotFount />} />
    </Routes>
  );
};
export default Routers;
