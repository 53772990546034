import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Axios from '../../utils/httpClient';
import LoaderRound from '../ui/LoaderRound';
import ArenaStyle from './ArenaStyle';

const Arena = ({ arena_id = 0, selectSector, setSelectSector, event_id, getSectorData = () => {} }) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(true);
	const [arenaData, setArenaData] = useState({ common_data: '' });

	useEffect(() => {
		if (arena_id) getArena();
		else setArenaData({});
	}, [arena_id]);
	const onClickEvent = (e, item_id, item_name, item_cat, item_line_align) => {
		getSectorData(item_id, item_name, item_cat);
		setSelectSector({
			id: item_id,
			name: item_name,
			category: item_cat,
			line_align: item_line_align,
		});
		// const element = document.getAnimations(`sb_${item_id}`);
		// if (element) {
		//   console.log("element", element);
		//   // element.fill = "#0BB10B";
		// }
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	};

	const getArena = () => {
		setLoading(true);
		Axios()
			.get(`ticket/arenas/${arena_id}/?event=${event_id}`)
			.then(r => {
				const elements = r?.data?.sections.map((item, index) => {
					return item?.is_block || item?.category === 4 ? (
						<g key={index} className={`sector sector_${item?.id}`} onMouseMove={e => moveHandle(e, item, item?.is_block)} onMouseLeave={e => leaveHandle(e, item)}>
							<path className='sector_body' d={item.svg} fill={item.svg_fill} />
							<path className='sector_text' d={item.svg_text} fill={item.svg_text_fill} />
						</g>
					) : (
						<g
							key={index}
							className={`sector sector_${item?.id}`}
							onClick={e => onClickEvent(e, item.id, item?.name, item?.category, item?.line_align)}
							onMouseMove={e => moveHandle(e, item, item?.is_block)}
							onMouseLeave={e => leaveHandle(e, item)}
						>
							<path className='sector_body' d={item.svg} fill={item.svg_fill} />
							<path className='sector_text' d={item.svg_text} fill={item.svg_text_fill} />
						</g>
					);
				});

				setArenaData({
					id: r?.data?.id,
					common_data: r?.data?.svg,
					view_box: r?.data?.view_box,
					sectors: elements,
				});
			})
			.catch(e => {})
			.finally(() => {
				setLoading(false);
			});
	};
	const moveHandle = (e, sector, is_block) => {
		// console.log(e, e.offsetX, e.offsetY);
		document.getElementById(`tooltip_${arena_id}`).style.display = 'block';
		document.getElementById(`tooltip_${arena_id}`).style.left = `${e.nativeEvent.layerX}px`;
		document.getElementById(`tooltip_${arena_id}`).style.top = `${e.nativeEvent.layerY - 50}px`;
		document.getElementById(`tooltip_${arena_id}`).innerHTML = `${is_block ? '' : `<b>${sector.count}</b>`} ${is_block ? t('ticket_single.not_sale') : t('ticket_single.seats')}`;
	};
	const leaveHandle = (e, sector) => {
		document.getElementById(`tooltip_${arena_id}`).style.display = 'none';
	};
	return (
		<div className=''>
			{loading ? (
				<div className='h-[192px] flex justify-center items-center'>
					<LoaderRound size={30} />
				</div>
			) : (
				<>
					<ArenaStyle
						className={`${selectSector?.id ? '!w-[125px] cursor-pointer' : ''}`}
						onClick={() => {
							if (selectSector?.id) setSelectSector(null);
						}}
					>
						<svg
							className='arena rounded-md'
							xmlns='http://www.w3.org/2000/svg'
							xmlnsXlink='http://www.w3.org/1999/xlink'
							width='100%'
							height='100%'
							viewBox={arenaData?.view_box}
							fill='none'
						>
							<g dangerouslySetInnerHTML={{ __html: arenaData.common_data }} />
							<g>{arenaData?.sectors}</g>
						</svg>

						<div id={`tooltip_${arena_id}`} className={`tooltip ${selectSector?.id ? '' : ''}`}></div>
					</ArenaStyle>
				</>
			)}
		</div>
	);
};

export default Arena;
