import i18next from 'i18next';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ArrowDownIcon from '../../svg/ArrowDownIcon';

const DropDownFilterComanda = ({ teamsData, SelectTeams }) => {
	const { t } = useTranslation();
	const [isOpen, setIsOpen] = useState(false);
	const [selectedVenueIds, setSelectedVenueIds] = useState([]);
	const [isHovered, setIsHovered] = useState(false);
	const wrapperRef = useRef(null);

	useClickOutside(wrapperRef, () => {
		setIsOpen(false);
	});

	function useClickOutside(ref, onClickOutside) {
		useEffect(() => {
			function handleClickOutside(event) {
				if (ref.current && !ref.current.contains(event.target)) {
					onClickOutside();
				}
			}
			document.addEventListener('mousedown', handleClickOutside);
			return () => {
				document.removeEventListener('mousedown', handleClickOutside);
			};
		}, [ref, onClickOutside]);
	}

	const handleSelectVenue = (venueId, venueName) => {
		const alreadySelected = selectedVenueIds.includes(venueId);
		let newSelectedVenueIds;
		if (alreadySelected) {
			newSelectedVenueIds = selectedVenueIds.filter(id => id !== venueId);
		} else {
			newSelectedVenueIds = [...selectedVenueIds, venueId];
		}
		setSelectedVenueIds(newSelectedVenueIds);
		SelectTeams(newSelectedVenueIds);
	};

	const handleSelectAll = () => {
		if (selectedVenueIds.length === teamsData.length) {
			// Deselect all if all are selected
			setSelectedVenueIds([]);
			SelectTeams([]);
		} else {
			// Select all if not all are selected
			const allVenueIds = teamsData.map(venue => venue.id);
			setSelectedVenueIds(allVenueIds);
			SelectTeams(allVenueIds);
		}
	};

	const handleClearSelection = () => {
		setSelectedVenueIds([]);
		SelectTeams([]);
		setIsOpen(false);
	};

	const shouldApplyHoverStyles = isHovered || isOpen;

	const updateSelectedVenueNames = useCallback(() => {
		// This logic might be redundant if we don't need to update venue names directly
	}, [teamsData, t]);

	useEffect(() => {
		i18next.on('languageChanged', updateSelectedVenueNames);

		return () => {
			i18next.off('languageChanged', updateSelectedVenueNames);
		};
	}, [updateSelectedVenueNames]);

	useEffect(() => {
		updateSelectedVenueNames();
	}, [selectedVenueIds, updateSelectedVenueNames]);

	return (
		<div ref={wrapperRef} className='relative w-full'>
			<button
				className={`relative w-full overflow-hidden px-4 py-2.5 rounded-2xl border 
        border-secondaryGray hover:bg-blue transition-all ease-in-out duration-200 hover:border-transparent
        ${isOpen ? 'bg-blue border-transparent' : ''}`}
				onClick={() => {
					setIsOpen(!isOpen);
				}}
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
			>
				<div className='relative z-10 flex items-center gap-2 justify-between text-lightGray h-[48px]'>
					<div className={`flex items-center transition-all ease-in-out duration-200 ${selectedVenueIds.length ? 'flex' : ''}`}>
						{/* <span className='mr-4'>
							<img src='/images/location.svg' alt='' className={`${shouldApplyHoverStyles ? 'hovered-img' : ''}`} />
						</span> */}
						<div className='flex flex-col items-start'>
							<span className={`${selectedVenueIds.length ? 'relative top-0' : ''} ${shouldApplyHoverStyles ? 'hovered-text' : ''}`}>{t('filter.teams')}</span>
							<span className={`text-secondLight ${shouldApplyHoverStyles ? 'hovered-text' : ''}`}>
								{selectedVenueIds.length
									? teamsData
											.filter(venue => selectedVenueIds.includes(venue.id))
											.map(venue => venue.name)
											.join(', ')
									: null}
							</span>
						</div>
					</div>
					<div className='flex items-center gap-2'>
						{selectedVenueIds.length > 0 && (
							<button
								onClick={e => {
									e.stopPropagation();
									handleClearSelection();
								}}
								className='ml-2 text-red-500'
							>
								&times;
							</button>
						)}
						{isOpen ? <ArrowDownIcon className='rotate-180' /> : <ArrowDownIcon color='#646A83' />}
					</div>
				</div>
				<span className='absolute top-0 left-0 z-[1] w-full h-full backdrop-blur-lg bg-blue-500/10' />
			</button>

			<div className='w-full '>
				{isOpen && (
					<div
						className={`shadow-[#ffffff50] shadow-md mt-4 absolute duration-200 transition-all ease-in-out top-[55px] right-0 h-auto bg-deepCove rounded-2xl z-[20] w-full ${
							isOpen ? 'box-shadow-open' : ''
						} `}
						style={{ boxShadow: '0px 2px 16px 0px rgba(0, 0, 0, 0.12)' }}
					>
						<div className='shadow-[#ffffff50] shadow-lg flex flex-col max-h-[400px] overflow-y-auto md:!overflow-y-visible overflow-x-hidden md:!max-h-auto w-full rounded-2xl'>
							<Link
								to='#'
								className='rounded-2xl text-secondLight py-2 font-normal text-base text-center duration-200 transition-all ease-in-out hover:bg-blue w-full'
								onClick={handleSelectAll}
							>
								{selectedVenueIds.length === teamsData.length ? t('filter.deall_teams') : t('filter.all_teams')}
							</Link>
							{teamsData.map((venue, index) => (
								<Link
									key={index}
									to='#'
									className={`w-full text-secondLight py-2 font-normal text-base text-center duration-200 transition-all ease-in-out hover:bg-blue ${
										selectedVenueIds.includes(venue.id) ? 'bg-blue text-white' : ''
									}`}
									onClick={() => handleSelectVenue(venue?.id, venue?.name)}
								>
									{venue?.name}
								</Link>
							))}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default DropDownFilterComanda;
