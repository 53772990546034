import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import LogoVisaWhite from '../../svg/LogoVisaWhite';
import { LinksData } from '../../utils/constans/Links'; // Make sure the path is correct
import DropDown from '../ui/DropDown';

export const Menu = ({ show, open, setOpen }) => {
	const links = LinksData();
	return (
		<>
			<div
				className={`fixed ${open ? 'opacity-100' : ' opacity-0 left-[-280px] '} 
      top-0 left-0 h-full z-999 bg-deepCove w-[280px] overflow-y-auto transition-all ease-in-out duration-500`}
			>
				<div className='py-[15px] px-5'>
					<Link to='/' className='cursor-pointer'>
						<img src='/images/brandWhite.png' className='w-[148px] h-[60px]' alt='' />
					</Link>
				</div>
				<ul className='flex flex-col lg:items-start items-center text-secondLight text-base font-normal'>
					{links?.map((item, index) => (
						<li key={index} className=' w-full' onClick={() => setOpen(!open)}>
							<HashLink to={item?.link} className='w-full'>
								<div className='relative overflow-hidden w-full px-4 py-2.5 border-t border-[#515665] hover:bg-blue transition-all ease-in-out duration-200 hover:border-transparent'>
									<span className='z-10 relative text-base'>{item?.title}</span>
								</div>
							</HashLink>
						</li>
					))}
				</ul>
			</div>
			<div
				onClick={() => setOpen(!open)}
				className={`fixed ${open ? 'opacity-100' : 'opacity-0 left-[-100%]'} 
      top-0 left-0 h-full z-[990] backdrop-blur-lg bg-blue-500/10 w-[100%] overflow-y-auto transition-all ease-in-out duration-500`}
			/>
		</>
	);
};

const Navigation = () => {
	const [open, setOpen] = useState(false);
	const [show, setShow] = useState(false);

	const handleShow = () => {
		if (window.scrollY >= 1) {
			setShow(true);
		} else {
			setShow(false);
		}
	};

	useEffect(() => {
		handleShow();
		window.addEventListener('scroll', handleShow);
		return () => window.removeEventListener('scroll', handleShow);
	}, []);

	const links = LinksData();

	return (
		<>
			<section className={`fixed w-full z-999 duration-200 transition-all ease-in-out ${show ? 'bg-deepCove' : ''}`}>
				<div className='container text-secondLight'>
					<div className='w-full py-[15px]'>
						<div className='flex w-full justify-between items-center'>
							<div className='flex items-center  gap-[60px]'>
								<Link
									to='/'
									className='cursor-pointer'
									onClick={() =>
										window.scrollTo({
											top: 0,
											left: 0,
											behavior: 'smooth',
										})
									}
								>
									<img src='/images/brand.png' className='md:w-[183px] w-[133px] md:h-[75px] h-[55px]' alt='' />
								</Link>
							</div>
							<div className=' hidden md:block'>
								<LogoVisaWhite className='min-h-[60px]' />
							</div>
							<ul className='lg:flex hidden lg:flex-row flex-col lg:items-start items-center gap-4 text-secondLight text-base font-normal'>
								{links?.map((item, index) => (
									<li key={index} className=''>
										<HashLink to={item?.link} className=''>
											<div className='relative px-4 py-2.5 rounded-[100px] hover:bg-blue transition-all ease-in-out duration-200 hover:border-transparent'>
												<span className='z-10 relative'>{item?.title}</span>
												<span className='absolute top-0 left-0 z-[1] w-full h-full blureffect rounded-[100px]' />
											</div>
										</HashLink>
									</li>
								))}
							</ul>
							<div className='flex gap-3 items-center'>
								<div className='text-secondLight'>
									<DropDown show={show} />
								</div>
								<div
									onClick={() => setOpen(!open)}
									className='flex lg:hidden relative overflow-hidden px-4 py-2.5 rounded-[100px] border 
        border-[#515665] hover:bg-blue transition-all ease-in-out duration-200 hover:border-transparent'
								>
									<img src='/images/menu-line.svg' className='z-10 relative' alt='' />

									<span className='absolute top-0 left-0 z-[1] w-full h-full backdrop-blur-lg bg-blue-500/10' />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{<Menu show={show} open={open} setOpen={setOpen} />}
		</>
	);
};

export default Navigation;
