import styled from "styled-components";

const StadionStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 1s ease-in-out;
  & > .arena {
    max-height: 100%;
    max-width: 800px;
  }
  & .sector {
    cursor: pointer;
    &:hover {
      & .sector_body {
        fill: red;
      }
    }
    & .select_sector {
      fill: #06e749;
    }
  }
  & .tooltip {
    text-wrap: nowrap;
    pointer-events: none;
    position: absolute;
    font-size: 18px;
    text-align: center;
    background: #06e749;
    padding: 5px 10px;
    z-index: 5;
    /* height: 30px; */
    line-height: 30px;
    margin: 0 auto;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 1px #eee;
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    display: none;
    &:after {
      content: "";
      position: absolute;
      left: 50%;
      top: 100%;
      width: 0;
      height: 0;
      margin-left: -10px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #06e749;
    }
  }
`;
export default StadionStyle;
