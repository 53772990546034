import { find, get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ArrowDownIcon from '../../svg/ArrowDownIcon';
import Axios from '../../utils/httpClient';
import BlurCard from '../ui/BlurCard';
import ButtonMain from '../ui/ButtonMain';
import InputUi from '../ui/InputUI';
import LoaderRound from '../ui/LoaderRound';
import ModalSave from '../ui/ModalSave';
import Title from '../ui/Title';
import toastUi from '../utilsSections/toastUi';

const Billingg = ({ ga4BeginCheckout, ga4Purchase }) => {
	const { t } = useTranslation();
	const [stateList, setStateList] = useState([]);
	const [obj, setObj] = useState({
		state: '',
	});
	const [objError, setObjError] = useState({});
	const [loading, setLoading] = useState(false);
	const [hasPrepended, setHasPrepended] = useState(false);
	const [birth_date, setBirthDate] = useState(new Date());
	const { my_order, my_places } = useSelector(s => s);
	const [orderLoading, setOrderLoading] = useState(false);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const changeInput = e => {
		setObj({ ...obj, [e.target.name]: e.target.value });
		setObjError({ ...objError, [e.target.name]: false });
	};

	const handleChangeNumber = e => {
		const { value } = e.target;
		if (value === '' || /^[0-9\b]+$/.test(value)) {
			setObj({ ...obj, [e.target.name]: e.target.value });
			setObjError({ ...objError, [e.target.name]: false });
		}
	};

	useEffect(() => {
		getDataList();
		getDataMe();
	}, []);

	const getDataList = () => {
		Axios()
			.get(`account/states/?page_size=1000`)
			.then(r => {
				setStateList(r?.data?.results || []);
			})
			.catch(e => {
				setStateList([]);
			})
			.finally(() => {});
	};

	const selectGetValue = (value, list = [], label_key = 'name', value_key = 'id') => {
		if (value) {
			const vObj = find(list, {
				[value_key]: value,
			});
			return {
				label: get(vObj, label_key, ''),
				value: get(vObj, value_key, ''),
			};
		}
		return null;
	};

	const getDataMe = () => {
		Axios()
			.get(`account/me/`)
			.then(r => {
				let data = r.data;
				// const dd = data?.birth_date?.split("-");
				setBirthDate(new Date(data?.birth_date == 'null' ? '' : data?.birth_date));
				setObj(pV => ({
					...pV,
					email: data?.email == 'null' ? '' : data?.email,
					last_name: data?.last_name == 'null' ? '' : data?.last_name,
					first_name: data?.first_name == 'null' ? '' : data?.first_name,
					birth_date: data?.birth_date == 'null' ? '' : data?.birth_date,
					// year_of_birth: get(dd, "0", null) ? parseInt(dd[0]) : null,
					// month_of_birth: get(dd, "1", null) ? parseInt(dd[1]) : null,
					// day_of_birth: get(dd, "2", null) ? parseInt(dd[2]) : null,
					city: data?.city == 'null' ? '' : data?.city,
					address: data?.address == 'null' ? '' : data?.address,
					zip_code: data?.zip_code == 'null' ? '' : data?.zip_code,
					state: data.state == 'null' ? '' : data.state,
					phone: data.phone == 'null' ? '' : data.phone,
				}));
			})
			.catch(e => {})
			.finally(() => {});
	};

	const handleBilling = e => {
		e.preventDefault();
		setLoading(true);

		let tt = true,
			err = {};

		if (!obj?.first_name) {
			tt = false;
			err = { ...err, first_name: true };
		}

		if (!obj?.last_name) {
			tt = false;
			err = { ...err, last_name: true };
		}

		if (!birth_date) {
			tt = false;
			err = { ...err, birth_date: true };
		}

		if (!obj?.state) {
			tt = false;
			err = { ...err, state: true };
		}

		if (!obj?.phone) {
			tt = false;
			err = { ...err, phone: true };
		}

		const formData = new FormData();
		formData.append('email', obj?.email);
		formData.append('last_name', obj?.last_name);
		formData.append('first_name', obj?.first_name);
		formData.append(
			'birth_date',
			`${new Date(birth_date).getFullYear()}-${String(new Date(birth_date).getMonth() + 1).padStart(2, '0')}-${String(new Date(birth_date).getDate()).padStart(2, '0')}`
		);
		formData.append('city', obj?.city);
		formData.append('address', obj?.address);
		formData.append('zip_code', obj?.zip_code);
		formData.append('state', obj?.state);
		formData.append('phone', obj?.phone);

		if (tt) {
			Axios()
				.put('account/users/update_profile/', formData)
				.then(res => {
					toastUi.success(t('others.information_saved'));
					setLoading(false);
					window.scrollTo({
						top: 0,
						left: 0,
						behavior: 'smooth',
					});
					ga4BeginCheckout();
					dispatch({
						type: 'SET_MODAL',
						payload: (
							<ModalSave title={t('payment.title')} btnSaveTitle={t('payment.btn')} saveAction={() => createOrder()} orderLoading={orderLoading}>
								{t('payment.text')}
							</ModalSave>
						),
					});
				})
				.catch(error => {
					console.log(error);
					if (error?.response?.status === 400) {
						// toastUi.error(error?.message);
					}
					if (error?.response?.status === 401) {
						// toastr.error(t("login.error_logging_in"));
					}
				})
				.catch(e => {})
				.finally(() => {
					setLoading(false);
				});
		} else {
			setObjError(err);
			setLoading(false);
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth',
			});
		}
	};
	const createOrder = () => {
		setOrderLoading(true);
		let orderObj = {
				event_id: my_order?.event_id,
				currency: my_order?.currency,
			},
			placeList = [];
		my_places.forEach(palce => {
			placeList.push({
				section: palce?.section,
				place: palce?.place?.map(s => s?.seat_id),
			});
		});
		orderObj = { ...orderObj, places: placeList.filter(item => item?.place?.length > 0) };
		Axios()
			.post('orders/orders/', orderObj)
			.then(res => {
				dispatch({
					type: 'SET_CLOSE_MADAL',
				});
				if (res?.data?.payment_url) {
					toastUi.success(t('billing.send_link'));
					// ga4Purchase();
					// window.open(res?.data?.payment_url);
					window.open(res?.data?.payment_url, '_self');
					// navigate("/congratulations");af
				} else {
					toastUi.error('Error Payment');
				}
			})
			.catch(error => {
				// if (error?.response?.status === 400) {
				toastUi.error(get(error, 'response.data.check.0', 'Error Payment'));
				// dispatch({
				//   type: "SET_CLOSE_MADAL",
				// });
				// }
			})
			.finally(() => {
				setOrderLoading(false);
			});
	};

	return (
		<BlurCard className='xl:col-span-7 col-span-12 !lg:p-8 p-4'>
			<ButtonMain onClick={() => navigate(-1)} type_color='t_blur' text={t('billing.back_button')} className='mb-[20px]' lefticon={<ArrowDownIcon className='rotate-90' />} />
			<Title title={t('billing.billing_info')} className='!text-left lg:!text-2xl !text-lg !lg:p-0 p-4' />
			<form onSubmit={handleBilling} className='grid grid-cols-2 gap-4'>
				<div className='lg:col-span-1 col-span-2'>
					<InputUi required type='text' label={t('billing.first_name')} name='first_name' value={obj?.first_name || ''} onChange={changeInput} />
					<div className='text-primaryOrange font-medium text-base'>{objError.first_name ? <div>{t('billing.filled')}</div> : ''}</div>
				</div>
				<div className='lg:col-span-1 col-span-2'>
					<InputUi required type='text' label={t('billing.last_name')} name='last_name' value={obj?.last_name || ''} onChange={changeInput} />
					<div className='text-primaryOrange font-medium text-base'>{objError.last_name ? <div>{t('billing.filled')}</div> : ''}</div>
				</div>
				<div className='lg:col-span-1 col-span-2'>
					<InputUi required type='text' label={t('verificationn.email')} name='email' className='select-none' value={obj?.email || ''} onChange={changeInput} disabled />
				</div>
				<div className='lg:col-span-1 col-span-2'>
					<InputUi type='text' label={t('billing.address')} name='address' value={obj?.address || ''} onChange={changeInput} />
				</div>
				<div className='col-span-2'>
					<InputUi
						type='date'
						required
						name='birth_date'
						label={t('billing.birth')}
						classNameError={`${!birth_date ? 'blureffectError !text-primaryOrange focus:border-primaryOrange' : 'blureffect !text-secondLight focus:border-blue'}`}
						value={birth_date || ''}
						onChange={setBirthDate}
					/>
					<div className='text-primaryOrange font-medium text-base'>{objError.birth_date ? <div>{t('billing.filled')}</div> : ''}</div>
				</div>
				<div className='lg:col-span-1 col-span-2'>
					<InputUi
						type='select'
						label={t('billing.state')}
						placeholder=''
						name='state'
						required
						classNameError={`${objError.birth_date ? 'errorSelect' : 'errorSelectFocus'}`}
						onChange={val => {
							setObj({
								...obj,
								state: val?.value,
							});
							setObjError(pV => ({
								...pV,
								state: false,
							}));
						}}
						value={selectGetValue(obj?.state, stateList, 'name', 'id')}
						options={stateList.map(({ name, id }) => ({
							label: name == null ? '' : name,
							value: id,
						}))}
					/>
					<div className='text-primaryOrange font-medium text-base'>{objError.state ? <div>{t('billing.filled')}</div> : ''}</div>
				</div>
				<div className='lg:col-span-1 col-span-2'>
					{' '}
					<InputUi
						required
						name='phone'
						// placeholder={t("refund.enter_phone")}
						label={t('refund.phone')}
						classNameError={`${objError.phone ? 'blureffectError !text-primaryOrange focus:border-primaryOrange' : 'blureffect !text-secondLight focus:border-blue'}`}
						value={obj?.phone || ''}
						onChange={e => {
							let newValue = e.target.value;
							if (newValue === '') {
								setHasPrepended(false);
							}
							if (!hasPrepended && newValue) {
								// newValue = "+ " + newValue;
								newValue = newValue;
								setHasPrepended(true);
							}
							setObj(pV => ({
								...pV,
								phone: newValue,
							}));
							setObjError(pV => ({
								...pV,
								phone: false,
							}));
						}}
						onKeyPress={event => {
							if (!/[0-9]/.test(event.key)) {
								event.preventDefault();
							}
						}}
					/>
					<div className='text-primaryOrange font-medium text-base'>{objError.phone ? <div>{t('billing.filled')}</div> : ''}</div>
				</div>
				<div className='lg:col-span-1 col-span-2'>
					<InputUi type='text' label={t('billing.zip_code')} name='zip_code' value={obj?.zip_code || ''} onChange={handleChangeNumber} />
				</div>
				<div className='lg:col-span-1 col-span-2'>
					<InputUi type='text' label={t('billing.city')} name='city' value={obj?.city || ''} onChange={changeInput} />
				</div>
				{/* <div className="lg:col-span-1 col-span-2"></div>
        <div className="lg:col-span-1 col-span-2"></div> */}
				<div className='col-span-2 mt-3'>
					<ButtonMain
						type={'submit'}
						type_color='t_transparent'
						text={loading ? <LoaderRound size={16} /> : t('billing.purchase')}
						className={`w-full !rounded-2xl uppercase `}
						activeBtn={true}
					/>
				</div>
			</form>
		</BlurCard>
	);
};

export default Billingg;
