import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// import required modules
import { Autoplay, Navigation, Pagination } from 'swiper/modules';

const FirstSlide2 = ({ list = [] }) => {
	const { t } = useTranslation();

	useEffect(() => {
		// const element = document.querySelector(".first_slide");
		// element.classList.add("expand_width");
	}, []);

	return (
		<div>
			<Swiper
				slidesPerView={1}
				spaceBetween={30}
				loop={true}
				autoplay={{
					delay: 50000000,
					disableOnInteraction: false,
				}}
				pagination={{
					clickable: true,
				}}
				navigation={false}
				modules={[Autoplay, Pagination, Navigation]}
				className='mySwiper'
			>
				{list?.map((item, index) => (
					<SwiperSlide key={index}>
						<img src={item?.image} className='w-full sm:h-full h-[320px] object-cover' alt='' />
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
};

export default FirstSlide2;
