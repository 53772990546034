import React from "react";

const ArrowDownIcon = (props) => {
  return (
    <svg
      {...props}
      width={props?.width || "10"}
      height={props?.height || "6"}
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 0.500033L5 4.5L9 0.5"
        stroke={props?.stroke || "#F3F3F4"}
        strokeWidth="1.3125"
        strokeMiterlimit="16"
      />
    </svg>
  );
};

export default ArrowDownIcon;
