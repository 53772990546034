import { forwardRef } from "react";

function CustomInput(props, ref) {
  return (
    <input
      {...props}
      ref={ref}
      className={`p-4 rounded-xl font-bold text-base w-full outline-none shadow-none focus:ring-0 text-secondLight ${props.className}`}
    />
  );
}

const ref = forwardRef((props, ref) => CustomInput(props, ref));

ref.displayName = "CustomInput";

export default ref;
