import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import GoogleMapIcon from '../../svg/GoogleMapIcon';
import ButtonMain from '../ui/ButtonMain';
import LoaderRound from '../ui/LoaderRound';
import Title from '../ui/Title';

const AboutFFWC2024 = ({ arenaData, loading }) => {
	const { t } = useTranslation();

	useEffect(() => {
		const urlHash = window.location.hash;
		if (urlHash.substring(1) === 'ffwc2024') {
			setTimeout(() => {
				if (urlHash.length) {
					const element = document.getElementById(urlHash.substring(1));
					element.scrollIntoView({
						behavior: 'smooth',
						block: 'start',
					});
				}
			}, 200);
		}
	}, []);

	return (
		<div className='container md:pt-[120px] pt-[90px] md:pb-[100px] pb-[60px]' id='ffwc2024'>
			<Title title={t('about.title')} />
			<div className='px-0 pt-10'>
				{loading ? (
					<div className='flex items-center justify-center min-h-[300px]'>
						<LoaderRound size={30} />
					</div>
				) : (
					<div className='flex flex-col gap-5 transition-all duration-1000 ease-in-out'>
						{arenaData?.map((about_item, about_index) => {
							const { name, desc, location, image } = about_item;
							return (
								<div
									className='min-h-[350px]  md:min-h-[326px] relative grid rounded-3xl border-borderColor border-[1px] grid-cols-1 md:grid-cols-[55%,45%] w-full md:pt-24 pb-10 py-8 px-4 md:px-12 gap-6'
									key={about_index}
									style={{
										backgroundImage: `url(${image})`,
										backgroundSize: 'cover',
										backgroundPosition: 'center',
									}}
								>
									<div
										className='absolute rounded-3xl top-0 left-0 w-full h-full z-[2]'
										style={{
											background: `linear-gradient(180deg, rgba(11, 23, 70, 0) -70.55%, rgba(11, 23, 70, 0.4) 4.74%, #0B1746 100%)`,
										}}
									/>
									<div
										className='absolute top-0 rounded-3xl left-0 w-full h-full z-[3]  opacity-0  duration-200 transition-all ease-in'
										style={{
											background: `linear-gradient(0deg, #0B1746, #0B1746),linear-gradient(180deg, rgba(11, 23, 70, 0) -70.55%, rgba(11, 23, 70, 0.4) 4.74%, #0B1746 100%)`,
										}}
									/>

									<div className='relative z-[4] md:col-span-1 flex align-bottom items-end col-span-2 rounded-3xl '>
										<h2 className='!font-FUTSALSPEED font-normal md:text-[36px] md:leading-[54px] leading-7 text-secondLight max-w-[900px] text-xl'>
											<span className='!select-none !font-FUTSALSPEED'>{name}</span>
										</h2>
									</div>
									<div className='relative z-[4] md:col-span-1 col-span-2 md:pr-6 pr-0'>
										<p className='text-secondLight font-normal text-base mt-4 mb-6'>{desc}</p>
										<a href={`https://www.google.com/maps?q=${location}`} target='_blank' rel='noreferrer'>
											<ButtonMain type_color='t_blur' lefticon={<GoogleMapIcon className='z-10 relative' />} text={t('about.check_maps')} />
										</a>
									</div>
								</div>
							);
						})}
					</div>
				)}
			</div>
		</div>
	);
};

export default AboutFFWC2024;
