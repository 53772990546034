import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CloseIcon from "../../svg/CloseIcon";
import Axios from "../../utils/httpClient";
import { setToken } from "../../utils/tokenStorge";
import toastUi from "../utilsSections/toastUi";
import BlurCard from "./BlurCard";
import ButtonMain from "./ButtonMain";
import CustomInput from "./CustomInput";
import LoaderRound from "./LoaderRound";
import OverflowHidden from "./OverflowHidden";
import Title from "./Title";

const Verificationn = ({ setVerificationModal, tab, setTab, ga4ViewCart }) => {
  const { t } = useTranslation();
  const [obj, setObj] = useState({
    email: "",
    verification: "",
  });
  const [objE, setObjE] = useState({});
  const [resetSms, setResetSms] = useState(false);
  const [loading, setLoading] = useState(false);
  const [smsCode, setSmsCode] = useState("");

  const [timeLeft, setTimeLeft] = useState(180); // 3 minutes in seconds
  const [isRunning, setIsRunning] = useState(false);

  const [capchaImg, setCapchaImg] = useState("");
  const [capchaKey, setCapchaKey] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [try_count, setTryCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    let timer;
    if (isRunning && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsRunning(false);

      setObj((pV) => ({
        ...pV,
        verification: "",
      }));
    }
    return () => clearInterval(timer);
  }, [isRunning, timeLeft]);

  useEffect(() => {
    getImgCapture();
  }, []);

  const changeInput = (e) => {
    setObj({ ...obj, [e.target.name]: e.target.value });
    setObjE({ ...objE, [e.target.name]: false });
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleChangeEmail = (e) => {
    const emailValue = e.target.value;

    setObj((pV) => ({
      ...pV,
      email: emailValue,
    }));
    setObjE((pV) => ({
      ...pV,
      email: false,
    }));
  };

  const handleChangeNumber = (e) => {
    setObj((pV) => ({
      ...pV,
      verification: e.target.value,
    }));
    setObjE((pV) => ({
      ...pV,
      verification: false,
    }));
  };

  const verifyEmailAgain = (e) => {
    e.preventDefault();

    getImgCapture();
    setTab(1);
    setObj((pV) => ({
      ...pV,
      verification: "",
      captcha: "",
    }));
  };

  const verifyEmail = (e) => {
    e.preventDefault();
    setLoading(true);
    let tt = true,
      err = {};

    if (!validateEmail(obj?.email)) {
      tt = false;
      err = { ...err, email: true };
    }

    if (!obj?.captcha) {
      tt = false;
      err = { ...err, captcha: true };
    }

    const formData = new FormData();
    formData.append("email", obj?.email);
    formData.append("captcha_key", capchaKey);
    formData.append("captcha_value", obj?.captcha);

    if (tt) {
      Axios()
        .post("account/users/verify_email/", formData)
        .then((res) => {
          toastUi.success(t("verificationn.verify_toast"));
          setLoading(false);
          if (timeLeft === 0) {
            setTimeLeft(180);
          }
          setIsRunning(true);
          setTab(2);
          setCaptcha("");
          setCapchaKey("");
        })
        .catch((error) => {
          if (error?.response?.status === 400) {
            if (error?.response?.data?.error?.[0] === "Invalid CAPTCHA") {
              toastUi.error(t("verificationn.verify_captch_error"));
              setTab(1);

              setObj((pV) => ({
                ...pV,
                captcha: "",
              }));
            } else if (error?.response?.data?.email?.length > 0) {
              setObj((pV) => ({
                ...pV,
                email: "",
              }));
              toastUi.error(t("refund.email_error"));
            } else {
              toastUi.error(error?.message);
              setTab(1);
            }
          }
          if (error?.response?.status === 401) {
            // toastr.error(t("login.error_logging_in"));
          }
          setTab(1);
        })
        .catch((e) => {})
        .finally(() => {
          setLoading(false);
        });
    } else {
      setObjE(err);
      setLoading(false);
    }
  };

  const getImgCapture = () => {
    Axios()
      .get("captcha/")
      .then((response) => {
        let data = response?.data;
        setCapchaImg(data?.captcha_image_url);
        setCapchaKey(data?.captcha_key);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleVerification = (e) => {
    e.preventDefault();

    let tt = true,
      err = {};

    if (!validateEmail(obj?.email)) {
      tt = false;
      err = { ...err, email: true };
    }

    if (!obj?.verification) {
      tt = false;
      err = { ...err, verification: true };
    }
    const formData = new FormData();
    formData.append("email", obj?.email);
    formData.append("sms_code", obj?.verification.replace(/\s/g, ""));

    if (tt) {
      Axios()
        .post("account/users/accept_sms/", formData)
        .then((res) => {
          setObj((pV) => ({
            ...pV,
            email: "",
            verification: "",
            captcha: "",
          }));
          setVerificationModal(false);
          setToken(get(res, "data.access", ""));
          setTab(3);
          toastUi.success(t("others.verified_successfully"));
          setLoading(false);
          // navigate("/billing");
          setTimeLeft(180);
          setIsRunning(false);
          ga4ViewCart();
        })
        .catch((error) => {
          setTryCount(error?.response?.data?.try_count);
          if (error?.response?.status === 400) {
            if (error?.response?.data?.msg === "Sms code not equal") {
              toastUi.error(t("verificationn.sms_code_not_equal"));

              setObj((pV) => ({
                ...pV,
                verification: "",
              }));
              setTab(2);
            }
          }
          if (error?.response?.status === 401) {
            // toastr.error(t("login.error_logging_in"));
          }
          setTab(2);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setObjE(err);
      setLoading(false);
    }
  };

  return (
    <OverflowHidden>
      <div className="relative w-full min-h-[100vh] h-full flex justify-center p-6 overflow-y-auto">
        {/* <img
        src="/images/slide/bg.png"
        className="absolute w-full min-h-[100vh] h-full object-cover top-0 left-0 z-[1]"
        alt=""
      /> */}
        <div className="fixed bottom-0 left-0 w-full h-full z-[2] bg-darkBlue opacity-80" />
        <div className="relative z-[3] mt-[80px] max-h-[80vh] ">
          <div
            onClick={() => setVerificationModal(false)}
            className="absolute z-[4] top-2.5 right-2.5 w-[32px] h-[32px] border border-greyMercury rounded-[7px] flex justify-center items-center cursor-pointer"
          >
            <CloseIcon width="12px" height="12px" />
          </div>
          <BlurCard className=" md:w-[608px] w-full] !bg-darkBlue">
            <Title
              title={t("verificationn.title")}
              className="lg:!text-2xl !text-lg mt-7"
            />
            <p className="font-normal text-base mt-5 text-center text-lightGray mb-[30px]">
              {t("verificationn.deliver")}
            </p>

            <form
              onSubmit={(e) => {
                if (tab === 1) {
                  verifyEmail(e);
                } else if (timeLeft === 0) {
                  verifyEmailAgain(e);
                } else {
                  handleVerification(e);
                }
              }}
              className="flex flex-col gap-6"
            >
              <div className="flex flex-col gap-1.5">
                <p className="font-normal text-xs text-lightGray">
                  {t("verificationn.email_address")}
                </p>
                <CustomInput
                  placeholder={t("verificationn.email")}
                  className={`${
                    objE.email
                      ? "blureffectError !text-primaryOrange focus:border-primaryOrange"
                      : "blureffect !text-secondLight focus:border-blue"
                  }`}
                  value={obj?.email}
                  onChange={handleChangeEmail}
                />
                <div className="text-primaryOrange font-medium text-base">
                  {objE.email ? <div>{t("verificationn.filled")}</div> : ""}
                </div>
              </div>
              {tab === 1 ? (
                <div className="flex flex-col gap-1.5">
                  {" "}
                  <div className="flex items-center mt-[15px] mb-[15px] relative overflow-hidden">
                    <img
                      src={capchaImg}
                      className="absolute top-[8px] left-[15px] w-[100px] h-[40px] z-[1]"
                      alt=""
                    />
                    <CustomInput
                      className={`pl-[130px] ${
                        objE.captcha
                          ? "blureffectError !text-primaryOrange focus:border-primaryOrange"
                          : "blureffect !text-secondLight focus:border-blue"
                      }`}
                      value={obj?.captcha}
                      onChange={(e) => {
                        setObj((pV) => ({
                          ...pV,
                          captcha: e.target.value,
                        }));
                        setObjE((pV) => ({
                          ...pV,
                          captcha: false,
                        }));
                      }}
                    />

                    <img
                      onClick={() => {
                        getImgCapture();
                        setObj((pV) => ({
                          ...pV,
                          captcha: "",
                        }));
                      }}
                      src="/images/reload.svg"
                      className="absolute top-[15px] right-[15px] cursor-pointer"
                      alt=""
                    />
                  </div>
                  <div className="text-primaryOrange font-medium text-base -mt-4">
                    {objE.captcha ? <div>{t("verificationn.filled")}</div> : ""}
                  </div>
                </div>
              ) : (
                ""
              )}
              {tab === 2 ? (
                <div className="flex flex-col gap-1.5">
                  <p className="font-normal text-xs text-lightGray">
                    {t("verificationn.verification_code")}
                  </p>
                  <CustomInput
                    placeholder={t("verificationn.verification_code")}
                    className={`${
                      objE.verification
                        ? "blureffectError !text-primaryOrange focus:border-primaryOrange"
                        : "blureffect !text-secondLight focus:border-blue"
                    }`}
                    value={obj?.verification}
                    onChange={handleChangeNumber}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <div className="text-primaryOrange font-medium text-base">
                    {objE.verification ? (
                      <div>{t("verificationn.incorrect")}</div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}

              {tab === 2 ? (
                <div className="flex gap-2 items-center text-secondLight">
                  <img src="/images/exclamationgrey.svg" alt="" />
                  <span className="font-normal text-sm text-lightGray">
                    {t("verificationn.resend")}
                    <div className="flex text-white">
                      <>
                        {isRunning ? (
                          <>
                            {Math.floor(timeLeft / 60)}:
                            {timeLeft % 60 < 10
                              ? `0${timeLeft % 60}`
                              : timeLeft % 60}
                          </>
                        ) : (
                          <ButtonMain
                            type={"submit"}
                            type_color="t_transparent"
                            text={t("verificationn.send")}
                            className={`!bg-transparent !border-none !p-0`}
                          />
                        )}
                      </>
                    </div>
                  </span>
                </div>
              ) : null}

              {tab === 1 ? (
                <ButtonMain
                  type={
                    objE.email && objE.verification && obj?.captcha
                      ? "button"
                      : "submit"
                  }
                  type_color="t_transparent"
                  text={
                    loading ? (
                      <LoaderRound size={16} />
                    ) : (
                      t("verificationn.verify")
                    )
                  }
                  className={`w-full !rounded-2xl uppercase ${
                    objE.email && objE.verification && obj?.captcha
                      ? "opacity-30 !hover:opacity-30 !cursor-default"
                      : ""
                  } `}
                  activeBtn={true}
                />
              ) : (
                <ButtonMain
                  disabled={try_count === 3 ? true : false}
                  type={
                    obj.email === "" ||
                    obj.verification === "" ||
                    obj?.captcha === ""
                      ? "button"
                      : "submit"
                  }
                  type_color="t_transparent"
                  text={
                    loading ? (
                      <LoaderRound size={16} />
                    ) : try_count === 3 ? (
                      t("verificationn.try_count")
                    ) : (
                      t("verificationn.billing")
                    )
                  }
                  className={`w-full !rounded-2xl uppercase ${
                    obj.email === "" ||
                    obj.verification === "" ||
                    obj?.captcha === "" ||
                    try_count === 3
                      ? "opacity-30 hover:!opacity-30 !cursor-default"
                      : ""
                  } `}
                  activeBtn={true}
                />
              )}
            </form>
          </BlurCard>
        </div>
      </div>
    </OverflowHidden>
  );
};

export default Verificationn;
