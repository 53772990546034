import React from 'react';
// import 'react-datepicker/dist/react-datepicker.css';
import InputMask from 'react-input-mask';
import DatePickerSingle from './DatePickerSingle';
import RSelect from './Select';
const InputUi = props => {
	return (
		<div>
			<div className={`flex relative ${props?.type === 'date' ? ' z-10 ' : ''} flex-col justify-between h-full gap-2 w-full p-0 border-none ${props?.className ?? ''}`}>
				{props?.label ? (
					<label htmlFor='' className='text-lightGray font-normal text-xs'>
						{props?.label} <span className='text-red-600'>{props?.required ? '*' : ''}</span>
					</label>
				) : null}
				{props?.type === 'select' ? (
					<RSelect options={props?.options} className={props?.classNameError} {...props} required={false} />
				) : props?.type === 'textarea' ? (
					<textarea
						{...props}
						required={false}
						cols='30'
						rows='2'
						className={`bg-[#2575FC1A] border border-[#FFFFFF4D]
            rounded-xl p-4
            ${props?.border_error ? props?.border_error : 'hover:border-blue'}  ${props?.classNameError}`}
						placeholder={props?.placeholder}
					/>
				) : props?.type === 'date' ? (
					<DatePickerSingle
						{...props}
						value={props?.value}
						onChange={props?.onChange}
						required={false}
						type='date'
						name={props?.name}
						style={props?.value ? { color: '#000' } : {}}
						className={`  w-full bg-[#2575FC1A] border border-[#FFFFFF4D]
					rounded-xl p-4 ${props?.border_error ? props?.border_error : 'hover:border-blue'}  ${props?.classNameError}`}
					/>
				) : props?.type === 'file' ? (
					<input
						{...props}
						required={false}
						placeholder={props?.placeholder}
						type='file'
						style={props?.value ? { color: '#000' } : {}}
						className={`bg-[#2575FC1A] border border-[#FFFFFF4D]
            rounded-xl p-4 ${props?.border_error ? props?.border_error : 'hover:border-blue'}  ${props?.classNameError}`}
					/>
				) : props?.type === 'phone' ? (
					<InputMask
						{...props}
						className={`bg-[#2575FC1A] border border-[#FFFFFF4D]
            rounded-xl p-4
          ${props?.border_error ? props?.border_error : 'hover:border-blue'}  ${props?.classNameError}`}
						required={false}
						mask={'+998 (nn) nnn-nn-nn'}
						// disabled={is_disabled}
						placeholder={'+998 (99) 999-99-99'}
						maskChar='_'
						alwaysShowMask={false}
						formatChars={{
							n: '[0-9]',
							a: '[A-Za-z]',
							'*': '[A-Za-z0-9]',
						}}
					/>
				) : (
					<input
						{...props}
						required={false}
						placeholder={props?.placeholder}
						type='text'
						className={`bg-[#2575FC1A] border border-[#FFFFFF4D]
            rounded-xl p-4 ${props?.border_error ? props?.border_error : 'hover:border-blue'} ${props?.classNameError}
            `}
					/>
				)}
			</div>
		</div>
	);
};

export default InputUi;
