import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { LinksData } from '../../utils/constans/Links';
import Axios from '../../utils/httpClient';

const Footer = () => {
	const links = LinksData();
	const { t } = useTranslation();
	const [isMobile, setIsMobile] = useState(false);
	const [file, setFile] = useState('');
	const lang = localStorage.getItem('i18nextLng') || 'uz';
	useEffect(() => {
		getOffer();
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 876);
		};

		window.addEventListener('resize', handleResize);

		// Check initial screen width
		handleResize();

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [lang]);
	const getOffer = () => {
		Axios()
			.get(`/base/legal-document/`)
			.then(res => {
				setFile(lang === 'uz' ? res?.data?.[0]?.file_uz : res?.data?.[0]?.file_en);
			})
			.catch(err => {
				console.log(err);
			});
	};
	return (
		<div className='overflow-hidden'>
			{console.log(lang, '')}
			<div
				className={`footerBg mt-[70px] w-screen ${isMobile ? 'h-full w-screen bg-top' : 'bg-cover bg-no-repeat bg-right'}`}
				style={{
					backgroundImage: isMobile ? "url('/images/footer-bg-mobile.png')" : "url('/images/breandbook.png')",
					backgroundSize: 'cover',
					position: 'relative',
				}}
			>
				<div
					className='absolute inset-0 max-h-[82px]'
					style={{
						backgroundImage: 'linear-gradient(rgba(5, 15, 55, 1), rgba(5, 15, 55, 0))',
						zIndex: 1,
					}}
				></div>
				<div className='container  flex flex-col justify-end z-10 h-full'>
					<div className={`w-full  flex flex-col-reverse lg:flex-row justify-between items-center py-10 gap-8 mt-auto z-10 `}>
						<p className='bg-[#050F3740]  overflow-hidden px-4 py-2.5 rounded-[100px] text-secondLight border border-[#515665] hover:bg-blue transition-all ease-in-out duration-200 hover:border-transparent'>
							{t('footer.copyright')} © {new Date().getFullYear()}. {t('footer.reserved')}
						</p>
						<ul className='flex md:flex-row flex-col gap-5 items-center text-secondLight text-base font-normal'>
							{links?.map((item, index) => (
								<li key={index}>
									<a href={item?.link} className='text-center'>
										<div className='relative bg-[#050F3740] overflow-hidden px-4 py-2.5 break-words rounded-[100px] border border-[#515665] hover:bg-blue transition-all ease-in-out duration-200 hover:border-transparent'>
											<span className='z-10 relative text-right'>{item?.title}</span>
											<span className='absolute top-0 left-0 z-[1] w-full h-full backdrop-blur-lg bg-blue-500/10' />
										</div>
									</a>
								</li>
							))}
							<li>
								<Link to='/refund' className='text-center'>
									<div className='relative bg-[#050F3740] overflow-hidden px-4 py-2.5 rounded-[100px] border border-[#515665] hover:bg-blue transition-all ease-in-out duration-200 hover:border-transparent'>
										<span className='z-10 relative'>{t('links.refund_ticket')}</span>
										<span className='absolute top-0 left-0 z-[1] w-full h-full backdrop-blur-lg bg-blue-500/10' />
									</div>
								</Link>
							</li>
							<li>
								<Link to={file} target='blank' className='text-center'>
									<div className='relative bg-[#050F3740] overflow-hidden px-4 py-2.5 rounded-[100px] border border-[#515665] hover:bg-blue transition-all ease-in-out duration-200 hover:border-transparent'>
										<span className='z-10 relative'>{t('links.offer')}</span>
										<span className='absolute top-0 left-0 z-[1] w-full h-full backdrop-blur-lg bg-blue-500/10' />
									</div>
								</Link>
							</li>
						</ul>
					</div>
				</div>

				{!isMobile && (
					<div
						className='absolute bottom-0 inset-0 h-full'
						style={{
							zIndex: 1,
						}}
					></div>
				)}
			</div>
		</div>
	);
};

export default Footer;
