const getUzLocale = (t) => ({
  name: "uz",
  months: [
    [t("months.january"), t("months.jan")],
    [t("months.february"), t("months.feb")],
    [t("months.march"), t("months.mar")],
    [t("months.april"), t("months.apr")],
    [t("months.may"), t("months.may")],
    [t("months.june"), t("months.jun")],
    [t("months.july"), t("months.jul")],
    [t("months.august"), t("months.aug")],
    [t("months.september"), t("months.sep")],
    [t("months.october"), t("months.oct")],
    [t("months.november"), t("months.nov")],
    [t("months.december"), t("months.dec")],
  ],
  weekDays: [
    [t("weekdays.saturday"), t("weekdays.sat")],
    [t("weekdays.sunday"), t("weekdays.sun")],
    [t("weekdays.monday"), t("weekdays.mon")],
    [t("weekdays.tuesday"), t("weekdays.tue")],
    [t("weekdays.wednesday"), t("weekdays.wed")],
    [t("weekdays.thursday"), t("weekdays.thu")],
    [t("weekdays.friday"), t("weekdays.fri")],
  ],
  digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
  meridiems: [
    ["AM", "am"],
    ["PM", "pm"],
  ],
});

export default getUzLocale;
