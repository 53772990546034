import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Axios from '../../utils/httpClient';

const OurPartners = () => {
	const [list, setList] = useState([]);
	const { t } = useTranslation();

	useEffect(() => {
		getPartners();
	}, []);

	const getPartners = () => {
		Axios()
			.get(`/base/partners/`)
			.then(r => {
				let data = r?.data?.results;
				setList(data);
			})
			.catch(e => {
				setList([]);
			})
			.finally(() => {});
	};

	console.log(list);
	return (
		<div className='md:pt-[120px] pt-[90px] w-full'>
			<div className='bg-white'>
				<div className='container px-[20px]'>
					<div className='overflow-hidden mt-10'>
						{list.map((item, index) => (
							<img key={index} src={window.innerWidth <= 768 ? item?.image_mobile : item?.image_desktop} className='w-full h-auto' alt='' />
						))}
						{/* {list.map((item, index) =>
                item?.type === 1 ? (
                  <div
                    className={`lg:col-span-1 md:col-span-2 sm:col-span-2 col-span-6 flex justify-center 
                    gap-6 w-full border-r-[2px] border-[#000] partnerss
                    `}
                    key={index}
                  >
                    <a
                      href={item?.link}
                      target="_blank"
                      className="lg:w-full md:w-[127px] md:h-[110px] w-[200px] h-[130px] lg:px-6 lg:py-4 rounded-2xl flex items-center justify-center"
                    >
                      <img
                        src={item?.image}
                        className="object-contain w-full h-full"
                        alt=""
                      />
                    </a>
                  </div>
                ) : (
                  ""
                )
              )} */}
					</div>
				</div>{' '}
				{/* <div className="container pt-[52px]">
          <div className="flex items-center justify-center w-full mb-6">
            <Title
              title={t("others.our_supporter")}
              className="!text-center !text-darkBlue"
            />
          </div>
          <div className="overflow-hidden">
            <div className="flex gap-4 justify-center w-full whitespace-nowrap">
              {[1].map((_, __) =>
                list.map((item, index) =>
                  item?.type === 2 ? (
                    <div className="flex gap-6 " key={index}>
                      <a
                        href={item?.link}
                        target="_blank"
                        className="w-[276px] h-[75px] px-6 py-4 rounded-2xl flex items-center justify-center"
                      >
                        <img
                          src={item?.image}
                          className="object-contain"
                          alt=""
                        />
                      </a>
                    </div>
                  ) : (
                    ""
                  )
                )
              )}
            </div>
          </div>
        </div> */}
			</div>
		</div>
	);
};

export default OurPartners;
