import Navigation from '../../components/layoutSections/Navigation';
// import Footer from "../../components/layoutSections/Footer";
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Axios from '../../utils/httpClient';

const Layout = ({ children }) => {
	const dispatch = useDispatch();
	const { user } = useSelector(s => s);
	useEffect(() => {
		// if (!user?.id) getUser();
	}, []);
	const getUser = () => {
		Axios()
			.get(`account/me/`)
			.then(r => {
				dispatch({ type: 'SET_USER', payload: r?.data?.user ?? {} });
			})
			.catch(e => {})
			.finally(() => {});
	};
	return (
		<div>
			<Navigation />
			{children}
		</div>
	);
};

export default Layout;
