import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AboutFFWC2024 from "../../../../components/home/AboutFFWC2024";
import FAQ from "../../../../components/home/FAQ";
import FirstSlide2 from "../../../../components/home/FirstSlide2";
import OurPartners from "../../../../components/home/OurPartners";
import PayWithVisa from "../../../../components/home/PayWithVisa";
import UpcomingMatches from "../../../../components/home/UpcomingMatches";
import Footer from "../../../../components/layoutSections/Footer";
import SliderLeft from "../../../../components/ui/SliderLeft";
import Axios from "../../../../utils/httpClient"; // Ensure the correct import path
import { removeToken } from "../../../../utils/tokenStorge";

const Home = () => {
  document.title = "FIFA Futsal World Cup Uzbekistan 2024";
  const { i18n } = useTranslation();
  const [arenaData, setArenaData] = useState([]);
  const [teamData, setTeamData] = useState([]);
  const [banner_list, setBannerList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    removeToken();
    getArenes();
    getBanner();
    getTeam();
  }, [i18n.language]);

  const getArenes = () => {
    setLoading(true);
    Axios()
      .get(`ticket/arenas/`)
      .then((response) => {
        setArenaData(response?.data?.results || []);
      })
      .catch((error) => {
        console.error("Error fetching arena data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getTeam = () => {
    setLoading(true);
    Axios()
      .get(`/ticket/country/`)
      .then((response) => {
        setTeamData(response?.data?.results || []);
      })
      .catch((error) => {
        console.error("Error fetching arena data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getBanner = () => {
    setLoading(true);

    Axios()
      .get(`/base/banners/`)
      .then((res) => {
        setBannerList(res?.data?.results);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div>
      <FirstSlide2 list={banner_list} />
      <SliderLeft />
      <UpcomingMatches arenaData={arenaData} teamData={teamData} />
      <AboutFFWC2024 arenaData={arenaData} loading={loading} />
      <PayWithVisa />
      <FAQ />
      <OurPartners />
      <Footer />
    </div>
  );
};

export default Home;
