import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ButtonMain from "./ButtonMain";
import LoaderRound from "./LoaderRound";

const ModalSave = ({
  children = "",
  title,
  btnSaveTitle,
  saveAction = () => {},
  orderLoading = false,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center justify-center w-full">
      <p className="font-semibold text-2xl text-secondLight mt-3.5 mb-2">
        {title}
      </p>
      <p className="text-sm font-normal text-secondLight text-center mt-3 sm:px-6 px-2">
        {children}
      </p>
      <div className="flex w-full flex-wrap gap-2 mt-8">
        {/* <ButtonMain
          text={"Close"}
          type="button"
          type_color="t_transparent"
          onClick={() => {
            dispatch({ type: "SET_CLOSE_MADAL" });
          }}
        /> */}
        {orderLoading ? (
          <div className="flex items-center justify-center min-h-[50px] col-span-1 lg:col-span-3">
            <LoaderRound size={20} />
          </div>
        ) : (
          <ButtonMain
            text={btnSaveTitle}
            type="button"
            className="w-full"
            type_color="t_transparent"
            onClick={() => saveAction()}
            activeBtn={true}
          />
        )}
      </div>
    </div>
  );
};

export default ModalSave;
