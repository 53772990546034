import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const PaymentOptions = ({ onChange = () => {}, options, setMyOrder }) => {
	const [selectedOption, setSelectedOption] = useState(1);
	const { t } = useTranslation();

	const handleOptionChange = option => {
		setSelectedOption(option?.id);
		setMyOrder({
			currency: option?.value,
		});
	};

	return (
		<div className='flex flex-col gap-3 '>
			<h3 className='text-[#646A83]  text-xs'>{t('others.payment_option')} </h3>
			<div className='flex flex-col gap-3'>
				{options?.map((item, index) => {
					return (
						<label
							key={index}
							className={`rounded-xl cursor-pointer  flex  justify-between items-center space-x-3 p-4  border border-[#FFFFFF4D]
                                    `}
							onClick={() => handleOptionChange(item)}
						>
							<div className='flex items-center gap-3'>
								<input
									type='radio'
									value={item?.id}
									checked={selectedOption === item?.id}
									onChange={() => handleOptionChange(item)}
									className='  border border-white bg-[#2575FC1A] form-radio text-green-500 checked:border checked:border-green  checked:bg-green '
									color='green'
								/>
								{item?.id === 2 ? (
									<span className='text-white text-sm font-semibold'>{t('others.uzbek_card')}</span>
								) : item?.id === 1 ? (
									<span className='text-white text-sm font-semibold'>Visa</span>
								) : (
									<span className='text-white text-sm font-semibold'>{t('others.foreign_card')}</span>
								)}
							</div>
							{item?.id === 1 ? <img src='/images/visa-logo.svg' alt='VISA' className='h-[20px]' /> : null}
						</label>
					);
				})}
			</div>
		</div>
	);
};

export default PaymentOptions;
