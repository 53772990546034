export const ga4_view_item = (items = []) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "view_item",
    ecommerce: {
      items: items,
    },
  });
};
export const ga4_select_item = (items = []) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "select_item",
    ecommerce: {
      items: items,
    },
  });
};
export const ga4_add_to_cart = (items = []) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "add_to_cart",
    ecommerce: {
      items: items,
    },
  });
};
export const ga4_view_cart = (items = []) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "view_cart",
    ecommerce: {
      items: items,
    },
  });
};
export const ga4_begin_checkout = (items = []) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "begin_checkout",
    ecommerce: {
      items: items,
    },
  });
};
export const ga4_purchase = (items = []) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "purchase",
    ecommerce: {
      items: items,
    },
  });
};
