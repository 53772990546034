import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import ArrowDownIcon from "../../svg/ArrowDownIcon";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const DropDown = ({ show }) => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef("menu");

  useClickOutside(wrapperRef, () => {
    setIsOpen(false);
  });

  function useClickOutside(ref, onClickOutside) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          onClickOutside();
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, onClickOutside]);
  }
  const onLanguage = (newLang) => {
    i18n.changeLanguage(newLang);
    window.localStorage.setItem("i18nextLng", newLang);
  };
  return (
    <div ref={wrapperRef} className="relative">
      <button
        className={`relative overflow-hidden px-4 py-2.5 rounded-[100px] border 
        border-[#515665] hover:bg-blue hover:rounded-[100px] transition-all ease-in-out duration-200 hover:border-transparent
        ${show ? "shadow-xl" : ""} ${
          isOpen ? "bg-blue border-transparent" : ""
        }`}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <div className="relative z-10 flex items-center gap-2 justify-center">
          <span>{i18n.language.toUpperCase()}</span>
          {isOpen ? (
            <ArrowDownIcon className="rotate-180" />
          ) : (
            <ArrowDownIcon color="#fff" />
          )}
        </div>
        <span className="absolute top-0 left-0 z-[1] w-full h-full backdrop-blur-lg bg-blue-500/10" />
      </button>

      <div>
        {isOpen && (
          <div
            className={`absolute duration-200 transition-all ease-in-out ${
              show ? "top-[67px]" : "top-[55px]"
            } right-0 min-w-[78px] h-auto bg-deepCove  ${
              show ? "rounded-b-[12px]" : "rounded-[12px]"
            }  z-[2] overflow-hidden`}
            style={{ boxShadow: "0px 2px 16px 0px rgba(0, 0, 0, 0.12)" }}
          >
            <div className="flex flex-col max-h-[400px] overflow-y-auto md:!overflow-y-visible overflow-x-hidden md:!max-h-auto">
              <Link
                to="#"
                className="text-secondLight py-2 font-normal text-base text-center duration-200 transition-all ease-in-out
                hover:bg-blue"
                onClick={() => {
                  onLanguage("en");
                  setIsOpen(!isOpen);
                }}
              >
                En
              </Link>
              {/* <Link
                to="#"
                className="text-secondLight py-2 font-normal text-base text-center duration-200 transition-all ease-in-out
                hover:bg-blue"
                onClick={() => {
                  onLanguage('ru');
                  setIsOpen(!isOpen);
                }}
              >
                Ru
              </Link> */}
              <Link
                to="#"
                className="text-secondLight py-2 font-normal text-base text-center duration-200 transition-all ease-in-out
                hover:bg-blue"
                onClick={() => {
                  onLanguage("uz");
                  setIsOpen(!isOpen);
                }}
              >
                Uz
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DropDown;
