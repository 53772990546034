import { find, get } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import BlurCard from "../../../../components/ui/BlurCard";
import ButtonMain from "../../../../components/ui/ButtonMain";
import CustomInput from "../../../../components/ui/CustomInput";
import InputUI from "../../../../components/ui/InputUI";
import LoaderRound from "../../../../components/ui/LoaderRound";
import Title from "../../../../components/ui/Title";
import toastUi from "../../../../components/utilsSections/toastUi";
import Axios from "../../../../utils/httpClient";

const RefundTicket = () => {
  document.title =
    "Ticket Refund Request | FIFA Futsal World Cup Uzbekistan 2024";
  const { t } = useTranslation();
  const [tab, setTab] = useState(1);
  const [obj, setObj] = useState({});
  const [date, setDate] = useState(new Date());
  const [objE, setObjE] = useState({});
  const [loading, setLoading] = useState(false);
  const [hasPrepended, setHasPrepended] = useState(false);
  const [capchaImg, setCapchaImg] = useState("");
  const [capchaKey, setCapchaKey] = useState("");
  const [reason_statusList, setReason_statusList] = useState([
    {
      name: t("refund.plans_changed"),
      id: "plans_have_changed",
    },
    {
      name: t("refund.other"),
      id: "other",
    },
  ]);

  const navigate = useNavigate();
  useEffect(() => {
    getImgCapture();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const changeInput = (e) => {
    setObj({ ...obj, [e.target.name]: e.target.value });
    setObjE({ ...objE, [e.target.name]: false });
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleChangeEmail = (e) => {
    const emailValue = e.target.value;

    setObj((pV) => ({
      ...pV,
      email: emailValue,
    }));
    setObjE((pV) => ({
      ...pV,
      email: false,
    }));
  };

  const handleTicketNumber = (e) => {
    const { value } = e.target;
    if (value === "" || (/^[0-9\b]+$/.test(value) && value.length <= 8)) {
      setObj((pV) => ({
        ...pV,
        ticket_id: value,
      }));
      setObjE((pV) => ({
        ...pV,
        ticket_id: false,
      }));
    }
  };

  const handleRefund = (e) => {
    e.preventDefault();

    let tt = true,
      err = {};

    if (!obj?.first_name) {
      tt = false;
      err = { ...err, first_name: true };
    }
    if (!obj?.last_name) {
      tt = false;
      err = { ...err, last_name: true };
    }

    if (!validateEmail(obj?.email)) {
      tt = false;
      err = { ...err, email: true };
    }

    if (!obj?.ticket_id) {
      tt = false;
      err = { ...err, ticket_id: true };
    }

    if (!date) {
      tt = false;
      err = { ...err, purchase_date: true };
    }

    if (!obj?.phone) {
      tt = false;
      err = { ...err, phone: true };
    }

    if (!obj?.ticket) {
      tt = false;
      err = { ...err, ticket: true };
    }

    if (!obj?.captcha_value) {
      tt = false;
      err = { ...err, captcha_value: true };
    }

    const formData = new FormData();
    formData.append("phone", obj?.phone);
    formData.append("email", obj?.email);
    formData.append("ticket_id", obj?.ticket_id);
    formData.append("first_name", obj?.first_name);
    formData.append("last_name", obj?.last_name);
    if (obj?.reason_status != undefined) {
      formData.append("reason_status", obj?.reason_status);
    }
    formData.append("purchase_date", date?.toISOString().split("T")[0]);
    formData.append("ticket", obj?.ticket);
    formData.append("captcha_key", capchaKey);
    formData.append("captcha_value", obj?.captcha_value);

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    if (tt) {
      Axios()
        .post("orders/refusal-order/", formData)
        .then((res) => {
          setObj({
            email: "",
            phone: "",
            ticket_id: "",
            reason_status: "",
            first_name: "",
            last_name: "",
            purchase_date: "",
            ticket: null,
          });
          setObjE({
            email: false,
            phone: false,
            ticket_id: false,
            reason_status: false,
            first_name: false,
            last_name: false,
            purchase_date: false,
            ticket: false,
          });
          setTab(2);
          setLoading(false);
          setCapchaKey("");
          // navigate("/billing");
        })
        .catch((error) => {
          if (error?.response?.status === 400) {
            // toastUi.error(error?.message);

            if (error?.response?.data?.error?.[0] === "Invalid CAPTCHA") {
              toastUi.error(t("verificationn.verify_captch_error"));
              setTab(1);

              setObj((pV) => ({
                ...pV,
                captcha_value: "",
              }));
            } else if (error?.response?.data?.email?.length > 0) {
              setObj((pV) => ({
                ...pV,
                email: "",
              }));
              toastUi.error(t("refund.email_error"));
            }
          }
          if (error?.response?.status === 401) {
            // toastr.error(t("login.error_logging_in"));
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setObjE(err);
      setLoading(false);
    }
  };

  const selectGetValue = (
    value,
    list = [],
    label_key = "name",
    value_key = "id"
  ) => {
    if (value) {
      const vObj = find(list, {
        [value_key]: value,
      });
      return {
        label: get(vObj, label_key, ""),
        value: get(vObj, value_key, ""),
      };
    }
    return null;
  };

  const allowedFileTypes = ["image/jpeg", "image/png", "application/pdf"];

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file && allowedFileTypes.includes(file.type)) {
      setObj((pV) => ({
        ...pV,
        ticket: file,
      }));
      setObjE((pV) => ({
        ...pV,
        ticket: false,
      }));
    } else {
      setObj((pV) => ({
        ...pV,
        ticket: null,
      }));
      toastUi.error(t("refund.allowed"));
    }
  };

  const getImgCapture = () => {
    Axios()
      .get("captcha/")
      .then((response) => {
        let data = response?.data;
        setCapchaImg(data?.captcha_image_url);
        setCapchaKey(data?.captcha_key);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="relative w-full min-h-[100vh] h-full flex justify-center p-6 pt-[80px] pb-[100px]">
      <img
        src="/images/slide/bg.png"
        className="absolute w-full min-h-[100vh] h-full object-cover top-0 left-0 z-[1]"
        alt=""
      />
      <div className="fixed bottom-0 left-0 w-full h-full z-[2] bg-darkBlue opacity-80" />
      <div className="relative z-[3] mt-[100px]">
        <BlurCard className="md:w-[608px] w-full] !bg-darkBlue">
          <Title
            title={tab === 1 ? t("refund.title") : t("refund.accepted")}
            className="lg:!text-2xl !text-lg"
          />
          <p className="font-normal text-base mt-5 text-center text-lightGray mb-[30px]">
            {tab === 1 ? t("refund.note") : t("refund.note2")}
          </p>

          {tab === 1 ? (
            <form onSubmit={handleRefund} className="flex flex-col gap-6">
              <div className="flex flex-col gap-1.5">
                <InputUI
                  required
                  name="first_name"
                  placeholder={t("refund.enter_first_name")}
                  label={t("refund.first_name")}
                  classNameError={`${
                    objE.first_name
                      ? "blureffectError !text-primaryOrange focus:border-primaryOrange"
                      : "blureffect !text-secondLight focus:border-blue"
                  }`}
                  value={obj?.first_name || ""}
                  onChange={changeInput}
                />
                <div className="text-primaryOrange font-medium text-base">
                  {objE.first_name ? <div>{t("refund.filled")}</div> : ""}
                </div>
              </div>
              <div className="flex flex-col gap-1.5">
                <InputUI
                  required
                  name="last_name"
                  placeholder={t("refund.enter_last_name")}
                  label={t("refund.last_name")}
                  classNameError={`${
                    objE.last_name
                      ? "blureffectError !text-primaryOrange focus:border-primaryOrange"
                      : "blureffect !text-secondLight focus:border-blue"
                  }`}
                  value={obj?.last_name || ""}
                  onChange={changeInput}
                />
                <div classNfame="text-primaryOrange font-medium text-base">
                  {objE.last_name ? <div>{t("refund.filled")}</div> : ""}
                </div>
              </div>

              <div className="flex flex-col gap-1.5">
                <InputUI
                  type="date"
                  required
                  name="purchase_date"
                  label={t("refund.purchase")}
                  classNameError={`${
                    objE.purchase_date
                      ? "blureffectError !text-primaryOrange focus:border-primaryOrange"
                      : "blureffect !text-secondLight focus:border-blue"
                  }`}
                  value={date || ""}
                  onChange={setDate}
                />
                <div className="text-primaryOrange font-medium text-base">
                  {objE.purchase_date ? <div>{t("refund.filled")}</div> : ""}
                </div>
              </div>
              <div className="flex flex-col gap-1.5">
                <InputUI
                  required
                  name="ticket_id"
                  placeholder={t("refund.enter_ticket_number")}
                  label={t("refund.ticket_number")}
                  classNameError={`${
                    objE.ticket_id
                      ? "blureffectError !text-primaryOrange focus:border-primaryOrange"
                      : "blureffect !text-secondLight focus:border-blue"
                  }`}
                  value={obj?.ticket_id || ""}
                  onChange={handleTicketNumber}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
                <div className="text-primaryOrange font-medium text-base">
                  {objE.ticket_id ? <div>{t("refund.filled")}</div> : ""}
                </div>
              </div>
              <div className="flex flex-col gap-1.5">
                <InputUI
                  required
                  label={t("refund.email")}
                  name="email"
                  placeholder="Email"
                  classNameError={`${
                    objE.email
                      ? "blureffectError !text-primaryOrange focus:border-primaryOrange"
                      : "blureffect !text-secondLight focus:border-blue"
                  }`}
                  value={obj?.email || ""}
                  onChange={handleChangeEmail}
                />
                <div className="text-primaryOrange font-medium text-base">
                  {objE.email ? <div>{t("refund.filled")}</div> : ""}
                </div>
              </div>
              <div className="flex flex-col gap-1.5">
                <InputUI
                  required
                  name="phone"
                  placeholder={t("refund.enter_phone")}
                  label={t("refund.phone")}
                  classNameError={`${
                    objE.phone
                      ? "blureffectError !text-primaryOrange focus:border-primaryOrange"
                      : "blureffect !text-secondLight focus:border-blue"
                  }`}
                  value={obj?.phone || ""}
                  onChange={(e) => {
                    let newValue = e.target.value;
                    if (newValue === "") {
                      setHasPrepended(false);
                    }
                    if (!hasPrepended && newValue) {
                      // newValue = "+ " + newValue;
                      newValue = newValue;
                      setHasPrepended(true);
                    }
                    setObj((pV) => ({
                      ...pV,
                      phone: newValue,
                    }));
                    setObjE((pV) => ({
                      ...pV,
                      phone: false,
                    }));
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
                <div className="text-primaryOrange font-medium text-base">
                  {objE.phone ? <div>{t("refund.filled")}</div> : ""}
                </div>
              </div>
              <div className="flex flex-col gap-1.5">
                <InputUI
                  type="select"
                  label={t("refund.reason")}
                  placeholder={t("refund.change_plans")}
                  name="reason_status"
                  onChange={(val) => {
                    setObj({
                      ...obj,
                      reason_status: val?.value,
                    });
                    setObjE((pV) => ({
                      ...pV,
                      reason_status: false,
                    }));
                  }}
                  value={selectGetValue(
                    obj?.reason_status,
                    reason_statusList,
                    "name",
                    "id"
                  )}
                  options={reason_statusList.map(({ name, id }) => ({
                    label: name == null ? "" : name,
                    value: id,
                  }))}
                />
              </div>

              <div className="flex flex-col gap-1.5">
                <p className="text-lightGray font-normal text-xs">
                  {t("refund.enter_ticket")}
                  <span className="text-red-600"> *</span>
                </p>
                <div
                  className={`relative inline-block cursor-pointer bg-[#2575FC1A] border border-[#FFFFFF4D]
                rounded-xl p-4 hover:border-blue ${
                  objE.ticket
                    ? "blureffectError !text-primaryOrange focus:border-primaryOrange"
                    : "blureffect !text-secondLight focus:border-blue"
                }`}
                >
                  <input
                    type="file"
                    id="fileInput"
                    onChange={handleFileChange}
                    className="absolute left-0 top-0 opacity-0 w-full h-full cursor-pointer"
                    accept="image/jpeg,image/png,image/jpg,application/pdf"
                  />
                  <label
                    htmlFor="fileInput"
                    className={`cursor-pointer ${
                      obj?.ticket ? "text-white" : "text-[#6a7180]"
                    } `}
                  >
                    {obj?.ticket
                      ? obj?.ticket?.name
                      : t("refund.enter_ticket_error")}
                  </label>
                </div>

                <div className="text-primaryOrange font-medium text-base">
                  {objE.ticket ? <div>{t("refund.filled")}</div> : ""}
                </div>
              </div>

              <div className="flex flex-col gap-1.5">
                {" "}
                <div className="flex items-center mt-[15px] mb-[15px] relative overflow-hidden">
                  <img
                    src={capchaImg}
                    className="absolute top-[8px] left-[15px] w-[100px] h-[40px] z-[1]"
                    alt=""
                  />
                  <CustomInput
                    className={`pl-[130px] ${
                      objE.captcha_value
                        ? "blureffectError !text-primaryOrange focus:border-primaryOrange"
                        : "blureffect !text-secondLight focus:border-blue"
                    }`}
                    value={obj?.captcha_value}
                    onChange={(e) => {
                      setObj((pV) => ({
                        ...pV,
                        captcha_value: e.target.value,
                      }));
                      setObjE((pV) => ({
                        ...pV,
                        captcha_value: false,
                      }));
                    }}
                  />

                  <img
                    onClick={() => {
                      getImgCapture();
                      setObj((pV) => ({
                        ...pV,
                        captcha_value: "",
                      }));
                    }}
                    src="/images/reload.svg"
                    className="absolute top-[15px] right-[15px] cursor-pointer"
                    alt=""
                  />
                </div>
                <div className="text-primaryOrange font-medium text-base -mt-4">
                  {objE.captcha_value ? (
                    <div>{t("verificationn.filled")}</div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <ButtonMain
                type={"submit"}
                type_color="t_transparent"
                text={loading ? <LoaderRound size={16} /> : t("refund.submit")}
                className={`w-full mt-4 !rounded-2xl uppercase`}
                activeBtn={true}
              />
            </form>
          ) : (
            <ButtonMain
              type={"button"}
              onClick={() => navigate("/")}
              type_color="t_transparent"
              text={loading ? <LoaderRound size={16} /> : t("refund.go_main")}
              className={`w-full mt-4 !rounded-2xl uppercase`}
              activeBtn={true}
            />
          )}
        </BlurCard>
      </div>
    </div>
  );
};

export default RefundTicket;
