import React from "react";
import { useTranslation } from "react-i18next";

const SliderLeft = () => {
  const { t } = useTranslation();
  return (
    <div className="overflow-hidden bg-blueMagenta border-y border-deepCloud">
      <div className="flex gap-4 animate-scrollHorizontal whitespace-nowrap">
        {[
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 1, 2, 3,
          4, 5, 6, 7, 8, 9, 10,
        ].map((_, index) => (
          <div className="flex gap-4 uppercase" key={index}>
            <div className="text-white font-bold italic text-xl py-[18px] flex gap-4 ">
              <img src="/images/animatevector1.svg" alt="" />
              <span className="pr-[40px]">{t("slider_left.toshkent")}</span>
            </div>
            <div className="text-white font-bold italic text-xl py-[18px] flex gap-4">
              <img src="/images/animatevector2.svg" alt="" />
              <span className="pr-[40px]">{t("slider_left.andijon")}</span>
            </div>
            <div className="text-white font-bold italic text-xl py-[18px] flex gap-4">
              <img src="/images/animatevector3.svg" alt="" />
              <span className="pr-[40px]">{t("slider_left.buxoro")}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SliderLeft;
