import React from 'react';
import LogoVisaWhite from '../../svg/LogoVisaWhite';

const PayWithVisa = () => {
	return (
		<div className=' w-full h-full relative '>
			<div
				className='absolute top-0 left-0 w-full h-full right-0 bottom-0 z-[2]'
				style={{
					background: 'linear-gradient(180deg, rgba(5, 15, 55, 0) 0%, #050F37 100%)',
				}}
			/>{' '}
			<div
				className=' rotate-180 absolute top-0 left-0 w-full h-full right-0 bottom-0 z-[2]'
				style={{
					background: 'linear-gradient(180deg, rgba(5, 15, 55, 0) 0%, #050F37 100%)',
				}}
			/>
			<img src='/images/paywithvisa_bg.png' alt='' className='left-0 right-0 absolute h-full bg-cover object-cover w-full' />
			<div className=' z-[3] relative py-[90px]   md:py-[100px] lg:py-[148px] px-5'>
				{/* <div className='px-4 pb-[70px] md:pb-[100px]  justify-center items-center flex gap-6 md:gap-9 lg:gap-[60px]'>
					<svg className=' max-h-[90px] md:max-h-[130px] xl:min-h-[130px] md:h-[100px]  h-[70px]' viewBox='0 0 106 35' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<path
							d='M39.9206 1.3951L26.1546 34.2374H17.1747L10.3991 8.02656C9.98808 6.41319 9.63225 5.82015 8.38183 5.14126C5.71509 3.86482 2.89935 2.92647 0 2.348L0.199381 1.3951H14.6585C15.6035 1.39336 16.518 1.72977 17.2365 2.34353C17.9551 2.95729 18.4304 3.80788 18.5764 4.74153L22.1539 23.7474L30.9968 1.3951H39.9206ZM75.1114 23.5142C75.1492 14.8482 63.1265 14.3687 63.2083 10.4947C63.2359 9.31889 64.3565 8.06644 66.8124 7.74541C69.686 7.47209 72.58 7.97969 75.1891 9.21461L76.6808 2.24988C74.1395 1.29535 71.4482 0.8023 68.7335 0.793945C60.3343 0.793945 54.4237 5.25888 54.3766 11.6511C54.3204 16.3818 58.5962 19.0176 61.8168 20.5891C65.1254 22.1984 66.2337 23.2311 66.2214 24.6706C66.1979 26.875 63.5815 27.8473 61.1359 27.8851C58.0976 27.9508 55.0932 27.2366 52.4095 25.8106L50.8718 33.0075C53.8842 34.1766 57.0897 34.7687 60.321 34.7527C69.2468 34.7527 75.0838 30.343 75.1114 23.5142ZM97.2888 34.2374H105.148L98.2887 1.39511H91.0367C90.2619 1.38745 89.5028 1.61296 88.8579 2.04235C88.213 2.47174 87.7121 3.08516 87.4204 3.80291L74.6697 34.2374H83.5903L85.3632 29.3308H96.2623L97.2888 34.2374ZM87.8069 22.6012L92.28 10.2687L94.8565 22.6012H87.8069ZM52.0619 1.3951L45.0347 34.2374H36.5384L43.5676 1.3951H52.0619Z'
							fill='#fff'
						/>
					</svg>
					<div className='border-[2.5px] border-[#fff] h-[130px]  xl:h-[260px]' />
					<img src='/images/brand.png' alt='' className='max-h-[80px]  sm:max-h-[100px] md:max-h-[130px] xl:max-h-[242px]  xl:h-[242px]' />
				</div>
				<div className='flex flex-col text-white justify-center items-center gap-8 md:gap-12 lg:gap-16'>
					<div className=' text-2xl md:text-4xl  lg:text-6xl'>Worldwide Partner</div>
					<div className=' text-3xl md:text-5xl lg:text-7xl font-semibold'>Pay with Visa</div>
				</div> */}
				<div className='flex justify-center'>
					<LogoVisaWhite className='h-[517px]' />
				</div>
			</div>
		</div>
	);
};

export default PayWithVisa;
