export const Anjd2Data = {
  sector_id: 91,
  914: {
    17519: [0, 0, 0],
  },
  915: {
    17521: [0, 0, 0],
  },
  916: {
    17523: [0, 0, 0],
  },
  917: {
    17525: [0, 0, 0],
  },
  918: {
    17527: [0, 0, 0],
  },
  919: {
    17529: [0, 0, 0],
  },
  920: {
    17531: [0, 0, 0],
  },

  921: {
    17538: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  },
  923: {
    17551: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  },
  924: {
    17563: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  },
};
