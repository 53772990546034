import styled from 'styled-components';

const BaseSeatsStyle = styled.div`
	width: max-content;
	margin: auto;
	transition: zoom 1s ease-out;
	user-select: none;
	& .s_row {
		background: rgba(255, 255, 255, 0.1);
		margin-top: -8px;
		width: 100%;
		display: flex;
		/* justify-content: space-between; */
		align-items: center;
		user-select: none;
		font-size: 8px;
		& .c_name {
			width: 20px;
			text-align: center;
			user-select: none;
			font-size: 14px;
		}
		& .c_row {
			text-align: center;
			user-select: none;
		}
		& .s_seat {
			border-radius: 4px 4px 0 0;
			border: 1px solid #000;
			background-color: yellow;
			width: 28px;
			display: inline-block;
			text-align: center;
			user-select: none;
			padding-top: 5px;
			padding-bottom: 10px;
			text-align: center;
			cursor: pointer;
			&:hover {
				background-color: green;
			}
			& .s_name {
				background-color: #fff;
				display: inline-block;
				color: #000;
				user-select: none;
				width: 16px;
				margin: auto;
				height: 16px;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			&.select_seat {
				background-color: #2575fc !important;
				user-select: none;
			}
			&.busy_seat {
				background-color: #f54500;
				user-select: none;
			}
		}
		& .empty_seat {
			background: transparent;
			border-color: transparent;
			cursor: grab;
			&:hover {
				background-color: transparent;
			}
			& .s_name {
				background: transparent;
				color: transparent;
			}
		}
	}
`;

export default BaseSeatsStyle;
