import React from "react";

const GoogleMapIcon = (props) => {
  return (
    <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.4279 10.4756L8.93719 14.1537L5.25408 17.8184C3.8843 16.0775 2.38852 14.3911 1.47656 12.3513L3.46181 9.31012L6.50667 6.37256C5.48902 7.63026 5.565 9.48004 6.73467 10.6497C7.98605 11.9011 10.0144 11.9011 11.2657 10.6497C11.3221 10.5933 11.3766 10.5351 11.4279 10.4756Z" fill="white" />
      <path d="M11.4855 10.4065C12.5121 9.14824 12.4392 7.29208 11.2658 6.11927C10.0144 4.86789 7.98602 4.86789 6.73469 6.11927C6.68214 6.17181 6.63208 6.22567 6.58398 6.28075L8.6845 2.86605L11.5343 0.390625C13.6627 1.06441 15.4232 2.56464 16.4396 4.51455L14.3606 7.86686L11.4855 10.4065Z" fill="white" />
      <path d="M6.50597 6.37239L1.47572 12.3513C0.976828 11.2364 0.6525 10.0161 0.619031 8.62333C0.6165 8.54416 0.615234 8.46438 0.615234 8.3846C0.615234 6.33532 1.35047 4.45699 2.57208 3.00049L6.50597 6.37239Z" fill="white" />
      <path d="M6.5063 6.37261L2.57227 3.00047C4.11052 1.16648 6.41883 0 9.00005 0C9.88341 0 10.7348 0.136687 11.5343 0.39L6.5063 6.37261Z" fill="white" />
      <path d="M17.3847 8.38407C17.3847 6.98818 17.0436 5.67193 16.4402 4.51416L5.25391 17.8185C6.52483 19.4351 7.68611 21.0991 8.16873 23.1367C8.1842 23.2019 8.20084 23.2782 8.21856 23.3639C8.39355 24.2117 9.60747 24.2117 9.78245 23.3639C9.80017 23.2782 9.81681 23.2019 9.83228 23.1367C11.1189 17.7042 17.2295 14.928 17.3812 8.62332C17.3833 8.54382 17.3847 8.46408 17.3847 8.38407Z" fill="white" />
    </svg>
  );
};

export default GoogleMapIcon;
