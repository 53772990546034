import React from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-multi-date-picker';
import getUzLocale from './uzbek';

const DatePickerSingle = ({ className, value, onChange, name }) => {
	const { t } = useTranslation();
	const uz = getUzLocale(t);

	return (
		<div className={`date-picker-container    ${className}`}>
			<DatePicker
				name={name}
				format='DD MMMM YYYY'
				className='bg-transparent'
				placeholder='dd-mm-yyyy'
				value={value}
				zIndex={100000000}
				onChange={date => {
					console.log(date, name, value, 'date');
					onChange(date);
				}}
				locale={uz} // use the custom Uzbek locale
			/>
		</div>
	);
};

export default DatePickerSingle;
