import React from "react";
import CRangePicker from "./DatePicker";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";
import CloseIcon from "../../svg/CloseIcon";

const ChoosePeriodUi = ({
  dateRange,
  openCalendar = () => {},
  onSelectDateRange = () => {},
}) => {
  const { t } = useTranslation();
  return (
    <button
      onClick={openCalendar}
      className={`group relative w-full overflow-hidden px-4 py-2.5 rounded-2xl border  
border-secondaryGray hover:bg-blue transition-all ease-in-out duration-200 hover:border-transparent`}
    >
      <div className="relative z-10 flex items-center gap-2 justify-between text-lightGray h-[48px]  w-full">
        <div
          className={`flex items-center transition-all ease-in-out duration-200  w-full`}
        >
          <span className="mr-4">
            <img
              src="/images/calendar.svg"
              alt=""
              className={`group-hover:brightness-[0] group-hover:invert-[1]`}
            />
          </span>
          <div className="flex flex-col items-start  w-full">
            <span className={`group-hover:text-white`}>
              {t("filter.period")}
            </span>

            {dateRange?.start_at_after && dateRange?.start_at_before ? (
              <div className="text-white flex justify-between items-center w-full">
                <span>
                  <Moment format="DD-MM-YYYY">
                    {dateRange?.start_at_after}
                  </Moment>{" "}
                  -{" "}
                  <Moment format="DD-MM-YYYY">
                    {dateRange?.start_at_before}
                  </Moment>
                </span>
                <span
                  className="hover:bg-red-500 p-[5px] rounded-[5px]"
                  onClick={() => onSelectDateRange({})}
                >
                  <CloseIcon width={10} height={10} />
                </span>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <span className="absolute top-0 left-0 z-[1] w-full h-full backdrop-blur-lg bg-blue-500/10" />
    </button>
  );
};

const ChoosePeriod = ({ onSelectDateRange, dateRange, isDisabled }) => {
  return (
    <div className="relative w-full">
      {isDisabled ? (
        <ChoosePeriodUi dateRange={dateRange} />
      ) : (
        <CRangePicker
          onSelectDateRange={onSelectDateRange}
          render={(value, openCalendar) => {
            return (
              <ChoosePeriodUi
                dateRange={dateRange}
                openCalendar={openCalendar}
                onSelectDateRange={onSelectDateRange}
              />
            );
          }}
        />
      )}
    </div>
  );
};

export default ChoosePeriod;
