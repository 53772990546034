import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ArrowDownIcon from '../../svg/ArrowDownIcon';
import Axios from '../../utils/httpClient';
import ButtonMain from '../ui/ButtonMain';
import ChoosePeriod from '../ui/ChoosePeriod';
import FormatDate from '../ui/FormatDate';
import LoaderRound from '../ui/LoaderRound';
import MatchTime from '../ui/MatchTime';

import DropDownFilter from '../ui/DropDownFilter';
import DropDownFilterComanda from '../ui/DropDownFilterComanda';
import Title from '../ui/Title';

const UpcomingMatches = ({ arenaData, teamData }) => {
	const { t, i18n } = useTranslation();
	const [list, setList] = useState([]);
	const [loading, setLoading] = useState(true);
	const [nextLoading, setNextLoading] = useState(false);
	const [nextPage, setNextPage] = useState(null);
	const [arenaId, setArenaId] = useState(null);
	const [comandaId, setComandaId] = useState([]);
	const [gameType, setGameType] = useState(null);
	const [dateRange, setDateRange] = useState({
		start_at_after: '',
		start_at_before: '',
	});

	useEffect(() => {
		getDataList(undefined, false, dateRange);
	}, [arenaId, gameType, dateRange, i18n.language, comandaId?.length]);

	const teamsParam = comandaId.map(id => `teams=${id}`).join('&');
	const getDataList = (
		api_url = `event/events/?page_size=12${arenaId && arenaId !== -1 ? `&arena=${arenaId}` : ''}${comandaId?.length !== 0 ? '&' + teamsParam : ''}${
			gameType && gameType !== -1 ? `&game_type=${gameType}` : ''
		}`,
		is_next_page = false,
		dateRange = {}
	) => {
		let url = api_url;
		if (is_next_page) {
			setNextLoading(true);
		} else {
			setLoading(true);
			const { start_at_after, start_at_before } = dateRange;
			if (start_at_after && start_at_before) {
				url += `&start_at_after=${start_at_after}&start_at_before=${start_at_before}`;
			}
		}

		Axios()
			.get(url)
			.then(response => {
				setList(prevList => (is_next_page ? [...prevList, ...(response?.data?.results || [])] : response?.data?.results || []));
				setNextPage(response?.data?.next || null);
				scrollToHash('upcomingmatches');
			})
			.catch(error => {
				console.error('Error fetching data', error);
			})
			.finally(() => {
				setLoading(false);
				setNextLoading(false);
			});
	};

	const scrollToHash = hash => {
		if (window.location.hash.substring(1) === hash) {
			setTimeout(() => {
				const element = document.getElementById(hash);
				if (element) {
					element.scrollIntoView({ behavior: 'smooth', block: 'start' });
				}
			}, 200);
		}
	};

	return (
		<div className='md:pt-[120px] pt-[90px]' id='upcomingmatches'>
			<div className='container mx-auto px-4'>
				<div className='flex items-center justify-between w-full mb-6'>
					<Title title={t('upcoming_matches.title')} />
				</div>
				{/* <div
					onClick={() => {
						window.dataLayer = window.dataLayer || [];
						window.dataLayer.push({
							event: 'add_to_cart',
							ecommerce: {
								items: [
									{
										item_name: 'test',
										item_id: 1,
										price: 20,
										quantity: 1,
									},
								],
							},
						});
					}}
				></div> */}
				<div className='grid grid-cols-1 lg:grid-cols-4 gap-6'>
					{loading ? (
						<div className='flex items-center justify-center min-h-[300px] col-span-1 lg:col-span-3'>
							<LoaderRound size={30} />
						</div>
					) : (
						<div className='col-span-1 lg:col-span-3 lg:order-0 order-1'>
							<div className='gap-6 mt-[30px] md:mt-[50px] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 xl:grid-cols-9 items-start'>
								{list?.map((item, index) => {
									const {
										arena,
										team,
										opposing_team,
										start_at,
										slug,
										team_second,
										opposing_team_second,
										start_at_second,
										match_number,
										match_number_second,
										// game_type,
										name,
									} = item;

									function getTimeFromDateTime(dateTimeString) {
										const date = new Date(dateTimeString);
										const hours = date.getHours();
										const minutes = date.getMinutes();
										const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
										return formattedTime;
									}

									const time1 = getTimeFromDateTime(start_at != null ? start_at : '');
									const time2 = getTimeFromDateTime(start_at_second != null ? start_at_second : '');

									return (
										<Link
											onClick={() => {
												document.title = `${name || ''} | FIFA Futsal World Cup Uzbekistan 2024`;
											}}
											to={`/ticket/${slug}`}
											className='border border-secondaryGray rounded-3xl overflow-hidden col-span-1 sm:col-span-2 md:col-span-3 lg:col-span-2 xl:col-span-3 group hover:bg-blue duration-200 transition-all ease-in-out flex flex-col justify-between h-full'
											key={index}
										>
											<div className='p-4 md:p-6 flex flex-col gap-6 border-b-secondaryGray border-b group-hover:border-[#4488fb] duration-200 transition-all ease-in-out'>
												<div className='flex flex-col justify-center gap-2 h-full'>
													<p className='text-center font-semibold text-base text-secondLight'>{arena?.name}</p>
													{/* <span className='!select-none text-lightGray text-base font-semibold text-center '>{match_number_second}</span> */}
													<span className='!select-none text-lightGray text-base font-semibold text-center '>
														{/* {game_type === 1 || game_type === 2 || game_type === 3 || game_type === 4 || game_type === 5 || game_type === 6
															? 'GS'
															: game_type === 7
															? 'R16'
															: game_type === 8
															? 'QF'
															: game_type === 9
															? 'SF'
															: '3RD&F'}{' '} */}
														{match_number ? `M${match_number}` : null} {match_number_second ? `& M${match_number_second}` : null}
													</span>
												</div>
												{opposing_team != null && team != null ? (
													<div className='flex gap-3 justify-between items-center'>
														<div className='flex flex-col gap-2 items-center justify-center w-full'>
															<div className='xl:w-[100px] lg:w-[70px] flex justify-center'>
																<div className='flex flex-col justify-center'>
																	<div className='flex justify-center mb-2'>
																		<img width={50} src={team?.flag} alt={team?.name} />
																	</div>
																	<p className='font-semibold text-xs text-secondLight text-center'>{team?.name}</p>
																</div>
															</div>
														</div>
														<Title
															title={start_at != 'NaN:NaN' || start_at ? start_at?.split('T')[1].split('+')[0].slice(0, 5) : 'VS'}
															textsm={true}
															color='group-hover:text-green'
														/>
														<div className='flex flex-col gap-2 items-center justify-center w-full'>
															<div className='xl:w-[100px] lg:w-[70px] flex justify-center'>
																<div className='flex flex-col justify-center w-full'>
																	<div className='flex justify-center mb-2'>
																		<img width={50} src={opposing_team?.flag} alt={opposing_team.name} />
																	</div>
																	<p className='font-semibold text-xs text-secondLight text-center'>{opposing_team.name}</p>
																</div>
															</div>
														</div>
													</div>
												) : (
													''
												)}
												{/* ******** */}
												{opposing_team_second != null && team_second != null ? (
													<div className='flex gap-3 justify-between items-center'>
														<div className='flex flex-col gap-2 items-center justify-center w-full'>
															<div className='xl:w-[100px] lg:w-[70px] flex justify-center'>
																<div className='flex flex-col justify-center w-full'>
																	<div className='flex justify-center mb-2'>
																		<img width={50} src={team_second?.flag} alt={team_second?.name} />
																	</div>
																	<p className='font-semibold text-xs text-secondLight text-center'>{team_second?.name}</p>
																</div>
															</div>
														</div>
														<Title
															title={
																start_at_second?.split('T')[1].split('+')[0].slice(0, 5) != 'NaN:NaN' || start_at_second
																	? start_at_second?.split('T')[1].split('+')[0].slice(0, 5)
																	: 'VS'
															}
															textsm={true}
															color='group-hover:text-green'
														/>
														<div className='flex flex-col gap-2 items-center justify-center w-full'>
															<div className='xl:w-[100px] lg:w-[70px] flex justify-center'>
																<div className='flex flex-col justify-center'>
																	<div className='flex justify-center mb-2'>
																		<img width={50} src={opposing_team_second?.flag} alt={opposing_team_second.name} />
																	</div>
																	<p className='font-semibold text-xs text-secondLight text-center'>{opposing_team_second.name}</p>
																</div>
															</div>
														</div>
													</div>
												) : (
													''
												)}
											</div>
											<div className='p-4 md:p-6 flex flex-col items-center h-full justify-center '>
												<p className='text-secondLight'>
													<FormatDate time={start_at} match_number={match_number} />
												</p>
											</div>
										</Link>
									);
								})}
							</div>
						</div>
					)}
					<div className='lg:col-span-1 lg:order-1 col-span-2 mt-[30px] md:mt-[50px] flex flex-col gap-3'>
						{/* <div className='mb-16 flex justify-center  lg:hidden '>
							<LogoVisaWhite className='max-h-[160px]' />
						</div> */}
						<ChoosePeriod isDisabled={loading} onSelectDateRange={setDateRange} dateRange={dateRange} />
						{/* <DropDownFilter arenaData={arenaData} onSelectVenue={setArenaId} /> */}
						<DropDownFilter onSelectVenue={setArenaId} arenaData={arenaData} />
						<MatchTime arenaData={arenaData} onSelectVenue={setGameType} />
						<DropDownFilterComanda SelectTeams={setComandaId} teamsData={teamData} />
						{/* <div className='mt-16 hidden  lg:block'>
							<LogoVisaWhite className='max-h-[160px]' />
						</div> */}
						<Link to={'tel:+998712059899'} className='border border-[#00C8785C] px-4 py-3 rounded-2xl bg-[#00C87814] flex gap-3'>
							<img src='/images/customer-service.svg' alt='' />
							<div className='flex flex-col'>
								<div className=' text-[#646A83] font-semibold text-xs leading-[16.34px]'>Support Service </div>
								<div className='text-[#F3F3F4] font-semibold  text-base leading-[21.79px]'>+998 71 205 98 99</div>
							</div>
						</Link>
					</div>
				</div>
				{nextPage && (
					<div className='flex justify-center mt-[60px]'>
						{nextLoading ? (
							<LoaderRound size={30} />
						) : (
							<ButtonMain
								onClick={() => getDataList(nextPage, true, dateRange)}
								type_color='t_transparent'
								text={t('upcoming_matches.more')}
								righticon={<ArrowDownIcon />}
							/>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default UpcomingMatches;
