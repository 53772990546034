import React from 'react';
import Select from 'react-select';
import { reactSelectConfigurationDate } from '../../utils/constans/SelectConfig';
const RSelect = props => {
	return (
		<Select
			maxMenuHeight={200}
			className={`border_error ${props?.classNameError}`}
			placeholder='Выберите вариант'
			{...reactSelectConfigurationDate}
			{...props}
			options={
				props?.options || [
					{ value: 'chocolate', label: 'Chocolate' },
					{ value: 'strawberry', label: 'Strawberry' },
					{ value: 'vanilla', label: 'Vanilla' },
					{ value: 'chocolate', label: 'Chocolate' },
					{ value: 'strawberry', label: 'Strawberry' },
					{ value: 'vanilla', label: 'Vanilla' },
					{ value: 'chocolate', label: 'Chocolate' },
					{ value: 'strawberry', label: 'Strawberry' },
					{ value: 'vanilla', label: 'Vanilla' },
					{ value: 'chocolate', label: 'Chocolate' },
					{ value: 'strawberry', label: 'Strawberry' },
					{ value: 'vanilla', label: 'Vanilla' },
					{ value: 'chocolate', label: 'Chocolate' },
					{ value: 'strawberry', label: 'Strawberry' },
					{ value: 'vanilla', label: 'Vanilla' },
				]
			}
			// menuPlacement="auto"
			// menuPosition="fixed"
		/>
	);
};

export default RSelect;
