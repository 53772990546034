import { get, maxBy } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Anjd2Data } from '../../utils/constans/AndijonD2Custom';
import ButtonMain from '../ui/ButtonMain';
import LoaderRound from '../ui/LoaderRound';
import Title from '../ui/Title';
import BaseSeatsStyle from './BaseSeatsStyle';

const BaseSeats = ({ loadingSector, sectorData, setMyPlaces, sector_id, sector_name, sector_category, my_places, busySeats, eventData, maxCount, calcCount, line_align, iconShow }) => {
	const { t } = useTranslation();
	const scrollContainerRef = useRef(null);
	const [isDown, setIsDown] = useState(false);
	const [startX, setStartX] = useState(0);
	const [scrollLeft, setScrollLeft] = useState(0);
	const [colorChange, setColorChange] = useState({
		id: '',
		status: false,
	});

	const handleMouseDown = e => {
		const scrollContainer = scrollContainerRef.current;
		setIsDown(true);
		setStartX(e.pageX - scrollContainer.offsetLeft);
		setScrollLeft(scrollContainer.scrollLeft);
	};
	const handleMouseLeave = () => {
		setIsDown(false);
	};
	const handleMouseUp = () => {
		setIsDown(false);
	};
	const handleMouseMove = e => {
		if (!isDown) return;
		e.preventDefault();
		const scrollContainer = scrollContainerRef.current;
		const x = e.pageX - scrollContainer.offsetLeft;
		const walk = (x - startX) * 2;
		scrollContainer.scrollLeft = scrollLeft - walk;
	};

	const checkIsMySector = (sector_id, row_id, seat_id) => {
		const seat = get(
			my_places.find(o => o.section === sector_id),
			'place',
			[]
		).find(s => s.row_id === row_id && s.seat_id === seat_id);
		if (seat?.seat_id) return 'select_seat';
		return '';
	};
	const checkIsBusy = seat_id => {
		return busySeats?.includes(seat_id);
	};
	useEffect(() => {
		const base_seats_target = document.getElementById('base_seats_target');
		var clientWidth = base_seats_target?.clientWidth;
		const max_count = get(maxBy(sectorData, 'count'), 'count', 0) + 4;
		const max_width = 28 * max_count;
		if (clientWidth && max_count && max_width > clientWidth) {
			const zoom = parseInt((clientWidth * 100) / max_width);
			const base_seats = document.getElementById('base_seats');
			if (base_seats) base_seats.style.zoom = `${zoom}%`;
		}
	}, [sectorData]);
	const zoomEvent = (e_zoom = '+') => {
		const base_seats = document.getElementById('base_seats');
		if (base_seats) {
			const zoom = base_seats.style.zoom;
			if (zoom) {
				// console.log("zoom", parseInt(zoom.replace("%", "")), e_zoom);
				const n_zoom = parseInt(zoom.replace('%', ''));
				if (e_zoom === '-' && n_zoom > 20) base_seats.style.zoom = `${n_zoom - 5}%`;
				else if (e_zoom === '+' && n_zoom < 150) base_seats.style.zoom = `${n_zoom + 5}%`;
			}
		}
	};
	return (
		<>
			<div className='flex w-full justify-between items-center flex-wrap gap-2 !lg:p-0 p-4'>
				<Title title={`${sector_name} - ${t('ticket_single.choose_seats')}`} className='!text-left lg:!text-2xl !text-lg' />
				<div className='flex gap-2'>
					<ButtonMain type_color='t_blur' activeBtn={true} text='-' onClick={() => zoomEvent('-')} />
					<ButtonMain type_color='t_blur' activeBtn={true} text='+' onClick={() => zoomEvent('+')} />
				</div>
			</div>
			{maxCount <= calcCount(my_places) ? (
				<div className='mt-2 text-red-500 font-normal text-sm text-center'>
					{t('ticket_single.max_seats')} {calcCount(my_places)}
				</div>
			) : null}
			<div
				id='base_seats_target'
				className={`w-full py-5 overflow-x-auto seat_drag_scroll`}
				ref={scrollContainerRef}
				onMouseDown={handleMouseDown}
				onMouseLeave={handleMouseLeave}
				onMouseUp={handleMouseUp}
				onMouseMove={handleMouseMove}
			>
				{loadingSector ? (
					<>
						<div className='h-[192px] flex justify-center items-center'>
							<LoaderRound size={30} />
						</div>
					</>
				) : (
					<>
						<BaseSeatsStyle id='base_seats' style={{ zoom: '100%' }}>
							{sectorData.map((row, row_index) => (
								<div
									key={row_index}
									id={`row_${row.id}`}
									className='s_row'
									style={{
										justifyContent: line_align === 2 ? 'left' : line_align === 3 ? 'right' : 'center',
									}}
								>
									<div className='c_name' style={{ marginLeft: row_index + 1 }}>
										{row?.name}
									</div>
									<div className='c_row'>
										{get(row, 'places', []).map((seat, index) => (
											<span
												id={`seat_${row.id}_${seat?.id}`}
												className='relative'
												key={index}
												// style={{ width: 28 - row_index / 2 }}
												onClick={() => {
													if (row?.section?.category === 5 && seat?.parent) {
													} else {
														if (!checkIsBusy(seat?.id)) {
															if (maxCount > calcCount(my_places) || checkIsMySector(sector_id, row?.id, seat?.id)) {
																setMyPlaces({
																	sector_id: sector_id,
																	sector_name: sector_name,
																	sector_category: sector_category,
																	seat: {
																		row_id: row?.id,
																		row_name: row?.name,
																		seat_id: seat?.id,
																		seat_name: seat?.name,
																		// parent: seat?.id,
																	},
																});

																if (maxCount > calcCount(my_places)) {
																	row?.places?.forEach(element => {
																		if (seat?.id === element?.parent) {
																			setColorChange({
																				id: element?.parent,
																				status: true,
																			});
																			setMyPlaces({
																				sector_id: sector_id,
																				sector_name: sector_name,
																				sector_category: sector_category,
																				seat: {
																					row_id: row?.id,
																					row_name: row?.name,
																					seat_id: element?.id,
																					seat_name: element?.name,
																					parentt: seat?.id,
																				},
																			});
																		} else {
																			setColorChange({
																				id: '',
																				status: false,
																			});
																		}
																	});
																}
															}
														}
													}
												}}
											>
												{sector_id === Anjd2Data.sector_id
													? get(Anjd2Data, `${row.id}.${seat?.id}`, []).map((empty, empty_index) => (
															<span key={empty_index} className={`s_seat h-[40px] empty_seat`}>
																<span className='s_name'>{empty}</span>
															</span>
													  ))
													: null}
												<span
													className={`s_seat ${
														checkIsBusy(seat?.id) && seat?.is_block === false
															? 'busy_seat !bg-[#737373]'
															: (row?.section?.category === 5 || row?.section?.category === 6) &&
															  seat?.parent &&
															  !checkIsMySector(sector_id, row?.id, seat?.id)
															? '!bg-[#646A83]'
															: colorChange?.id == seat?.id
															? '!bg-blue'
															: seat?.is_block === true
															? 'busy_seat'
															: checkIsMySector(sector_id, row?.id, seat?.id)
													} h-[40px]`}
												>
													<span className='s_name'>{seat?.name}</span>
													<span className='absolute bottom-[-18px] left-0 flex justify-center w-full'>
														{row?.section?.category === 5 || row?.section?.category === 6 ? (
															<>
																{seat?.parent ? (
																	<img src='/images/roundediabled.svg' className='w-[10px] h-[12px]' alt='' />
																) : (
																	<img src='/images/seatuser.svg' className='w-[10px] h-[12px]' alt='' />
																)}
															</>
														) : (
															''
														)}
													</span>
												</span>
											</span>
										))}
									</div>
									<div className='c_name' style={{ marginRight: row_index + 1 }}>
										{row?.name}
									</div>
								</div>
							))}
						</BaseSeatsStyle>
					</>
				)}
			</div>
		</>
	);
};

export default BaseSeats;
