import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Arena from '../../../../../components/arena/Arena';
import BaseSeats from '../../../../../components/arena/BaseSeats';
import Billingg from '../../../../../components/ui/Billingg';
import BlurCard from '../../../../../components/ui/BlurCard';
import ButtonMain from '../../../../../components/ui/ButtonMain';
import CustomInput from '../../../../../components/ui/CustomInput';
import FormatDate from '../../../../../components/ui/FormatDate';
import LoaderRound from '../../../../../components/ui/LoaderRound';
import PaymentOptions from '../../../../../components/ui/Payment';
import Title from '../../../../../components/ui/Title';
import Verificationn from '../../../../../components/ui/Verificationn';
import PriceFormat from '../../../../../components/utilsSections/PriceFormat';
import ArrowDownIcon from '../../../../../svg/ArrowDownIcon';
import CloseIcon from '../../../../../svg/CloseIcon';
import { ga4_add_to_cart, ga4_begin_checkout, ga4_purchase, ga4_select_item, ga4_view_cart, ga4_view_item } from '../../../../../utils/ga4_actions/actions';
import Axios from '../../../../../utils/httpClient';

export const TeamTitle = ({ title, img }) => {
	return (
		<div className='inline-flex md:flex-row flex-col md:!items-start !items-center gap-1 text-secondLight'>
			<div className='flex md:flex-row flex-col items-center gap-2'>
				<img src={img} className='md:w-[30px] md:h-[30px] w-[25px] h-[25px] rounded-[8px] object-cover' alt='' />
				<span className='md:text-sm text-xs font-normal md:text-left text-center'>{title}</span>
			</div>
		</div>
	);
};

export const TeamInfo = ({ title, desc, textLg = false }) => {
	return (
		<div className='flex w-full justify-between items-center gap-3'>
			<p className={`${textLg ? 'md:!text-lg !text-base' : '!text-sm'}  font-normal text-lightGray`}>{title}</p>
			<p className='text-right md:text-base text-sm font-bold text-secondLight'>{desc}</p>
		</div>
	);
};

const TicketSingle = () => {
	const { t } = useTranslation();
	const { slug = '_' } = useParams();
	const navigate = useNavigate();
	const { my_order, my_places } = useSelector(s => s);
	const dispatch = useDispatch();

	const [maxCount, setMaxCount] = useState(1);
	const [tab, setTab] = useState(1);
	const [loading, setLoading] = useState(true);
	const [loadingSector, setLoadingSector] = useState(false);
	const [eventData, setEventData] = useState({});
	const [sectorData, setSevtorData] = useState({});
	const [priceSeat, setPriceSeat] = useState([]);
	const [selectSector, setSelectSector] = useState({});
	const [busySeats, setBusySeats] = useState([]);
	const [verificationModal, setVerificationModal] = useState(false);
	const [currency_list, setCurrency_list] = useState([]);

	useEffect(() => {
		if (get(eventData, 'id', false)) {
			ga4_select_item([
				{
					item_id: get(eventData, 'id', ''),
					item_name: get(eventData, 'name', ''),
					coupon: get(eventData, 'slug', ''),
					arena_id: get(eventData, 'arena.id', ''),
					arena_name: get(eventData, 'arena.name', ''),
					seats: my_places,
				},
			]);
		}
	}, [my_places]);
	useEffect(() => {
		getEventData();
		dispatch({ type: 'CLEAR_ORDER' });
		getCurrency();
	}, [slug]);
	useEffect(() => {
		if (eventData?.id) setMyOrder({ event_id: eventData?.id });
		if (eventData?.type) getSectorPrice(eventData?.type);
	}, [eventData]);

	const setMyPlaces = seat => {
		dispatch({ type: 'SET_MY_PLACES', payload: seat });
		const seat_elm = document.getElementById(`seat_${seat.seat.row_id}_${seat.seat.seat_id}`);
		if (seat_elm?.classList) {
			if (seat_elm.classList.contains('select_seat')) {
				seat_elm.classList.remove('select_seat');
			} else {
				seat_elm.classList.add('select_seat');
			}
		}
	};
	const setMyOrder = obj => {
		dispatch({ type: 'SET_MY_ORDER', payload: obj });
	};
	const getEventData = (api_url = `event/events/${slug}`) => {
		setLoading(true);
		Axios()
			.get(api_url)
			.then(r => {
				setEventData(r?.data || {});
				setMaxCount(r?.data?.max_count || 1);
				document.title = `${r?.data?.name || ''} | FIFA Futsal World Cup Uzbekistan 2024`;
				ga4_view_item([
					{
						item_id: get(r, 'data.id', ''),
						item_name: get(r, 'data.name', ''),
						coupon: get(r, 'data.slug', ''),
						arena_id: get(r, 'data.arena.id', ''),
						arena_name: get(r, 'data.arena.name', ''),
					},
				]);
			})
			.catch(e => {})
			.finally(() => {
				setLoading(false);
			});
	};
	const getSectorData = (sector_id, sector_name, sector_cat) => {
		setLoadingSector(true);
		Axios()
			.get(`ticket/lines?section=${sector_id}`)
			.then(r => {
				setSevtorData(get(r, 'data', []).reverse());
			})
			.catch(e => {})
			.finally(() => {
				setLoadingSector(false);
			});
		getSectorSeatBusy(sector_id);
	};
	const getSectorSeatBusy = section_id => {
		Axios()
			.get(`orders/items/?event_id=${eventData?.id}&section_id=${section_id}&page_size=1000`)
			.then(r => {
				let l = [];
				r?.data?.results?.forEach(s => {
					l.push(s?.place_id_id);
				});
				setBusySeats(l);
			})
			.catch(e => {})
			.finally(() => {});
	};
	const getSectorPrice = type_id => {
		Axios()
			.get(`event/section-prices/?type=${type_id}`)
			.then(r => {
				setPriceSeat(get(r, 'data', []));
			})
			.catch(e => {})
			.finally(() => {});
	};
	const getCurrency = () => {
		Axios()
			.get(`/base/currency/`)
			.then(r => {
				let data = r?.data?.results;
				let newCurrency = [];

				data?.forEach(e => {
					if (e?.status === true) {
						newCurrency = [
							...newCurrency,
							{
								label: e?.name,
								value: e?.currency,
								id: e?.id,
							},
						];
					}
				});

				setCurrency_list(newCurrency);
				setMyOrder({
					currency: get(newCurrency, '2.value', 2),
				});
			})
			.catch(e => {})
			.finally(() => {});
	};
	const formatDate = dateString => {
		const date = new Date(dateString);
		const day = date.getDate();
		const ordinalSuffix = n => {
			const s = ['th', 'st', 'nd', 'rd'];
			const v = n % 100;
			return s[(v - 20) % 10] || s[v] || s[0];
		};
		const options = {
			month: 'long',
			year: 'numeric',
		};
		return ` ${day} ${ordinalSuffix(day)} ${date.toLocaleDateString('en-GB', options)}`;
	};
	const calcTotalPrice = list_my_places => {
		let total_uzs = 0,
			total_usd = 0;
		// console.log(priceSeat, list_my_places);
		list_my_places.forEach(sector => {
			const sPrice = priceSeat.find(o => o.category === sector.sector_category);
			total_uzs += get(sPrice, 'price', 0) * get(sector, 'place', []).length;
			total_usd += get(sPrice, 'price_usd', 0) * get(sector, 'place', []).length;
		});
		return { total_usd, total_uzs };
	};

	const calcCount = list => {
		let count = 0;
		// console.log(list);
		list?.forEach(l => {
			if (l.sector_category === 5) {
				count += l?.place?.length / 2;
			} else {
				count += l?.place?.length;
			}
		});
		return count;
	};

	function getTimeFromDateTime(dateTimeString) {
		// Parse the date-time string
		const date = new Date(dateTimeString);

		// Extract the hours and minutes
		const hours = date.getHours();
		const minutes = date.getMinutes();

		// Format the time to HH:MM
		const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;

		return formattedTime;
	}

	const time1 = getTimeFromDateTime(eventData?.start_at != null ? eventData?.start_at : '');
	const time2 = getTimeFromDateTime(eventData?.start_at_second != null ? eventData?.start_at_second : '');
	const ga4ViewCart = () => {
		ga4_view_cart([
			{
				item_id: get(eventData, 'id', ''),
				item_name: get(eventData, 'name', ''),
				coupon: get(eventData, 'slug', ''),
				arena_id: get(eventData, 'arena.id', ''),
				arena_name: get(eventData, 'arena.name', ''),
				seats: my_places,
			},
		]);
	};
	const ga4BeginCheckout = () => {
		ga4_begin_checkout([
			{
				item_id: get(eventData, 'id', ''),
				item_name: get(eventData, 'name', ''),
				coupon: get(eventData, 'slug', ''),
				arena_id: get(eventData, 'arena.id', ''),
				arena_name: get(eventData, 'arena.name', ''),
				seats: my_places,
			},
		]);
	};
	const ga4Purchase = () => {
		ga4_purchase([
			{
				item_id: get(eventData, 'id', ''),
				item_name: get(eventData, 'name', ''),
				coupon: get(eventData, 'slug', ''),
				arena_id: get(eventData, 'arena.id', ''),
				arena_name: get(eventData, 'arena.name', ''),
				seats: my_places,
			},
		]);
	};

	return (
		<>
			<div className='relative mb-10'>
				<img src='/images/slide/bg.png' className='absolute w-full min-h-[100vh] h-full object-cover top-0 left-0 z-[1]' alt='' />
				<div
					className='fixed bottom-0 left-0 w-full h-full z-[2]'
					style={{
						background: 'linear-gradient(180deg, rgba(5, 15, 55, 0) 24.84%, rgba(5, 15, 55, 0.9) 85.97%, #050F37 96.75%)',
					}}
				/>
				<div className='container z-[3] relative pt-[150px]'>
					<div className='grid grid-cols-12 gap-6 text-white w-full items-start'>
						{tab === 3 ? (
							<Billingg ga4BeginCheckout={ga4BeginCheckout} ga4Purchase={ga4Purchase} />
						) : (
							<div className='lg:col-span-7 col-span-12 !lg:p-8 px-0 pb-0 overflow-hidden'>
								<BlurCard>
									<ButtonMain
										onClick={() => navigate(-1)}
										type_color='t_blur'
										text={t('ticket_single.back_button')}
										className=' !lg:m-0 m-4'
										lefticon={<ArrowDownIcon className='rotate-90' />}
									/>
									{!selectSector?.id ? <Title title={t('ticket_single.choose_section')} className='!text-left lg:!text-2xl !text-lg !lg:p-0 p-4' /> : null}
									<div>
										{selectSector?.id ? (
											<BaseSeats
												sector_id={selectSector?.id}
												sector_name={selectSector?.name}
												sector_category={selectSector?.category}
												line_align={selectSector?.line_align}
												my_places={my_places}
												loadingSector={loadingSector}
												sectorData={sectorData}
												setMyPlaces={setMyPlaces}
												busySeats={busySeats}
												eventData={eventData}
												maxCount={maxCount}
												calcCount={calcCount}
											/>
										) : null}
									</div>
									<div>
										<Arena
											selectSector={selectSector}
											setSelectSector={setSelectSector}
											arena_id={get(eventData, 'arena.id', '')}
											event_id={get(eventData, 'id', '')}
											getSectorData={getSectorData}
										/>
										{maxCount > calcCount(my_places) && selectSector?.id ? (
											<div className='flex gap-2 items-center font-normal text-sm text-secondLight mt-4'>
												<img src='/images/exclamation.svg' alt='' /> <span>{t('ticket_single.rule')}</span>
											</div>
										) : null}
									</div>
								</BlurCard>
							</div>
						)}
						<div className='lg:col-span-5 col-span-12 '>
							<BlurCard>
								<Title title={t('ticket_single.cart')} className='!text-left lg:!text-2xl !text-lg' />
								{loading ? (
									<BlurCard className='md:!p-4 !p-3 lg:mt-[40px] mt-[20px]'>
										<div className='h-[192px] flex justify-center items-center'>
											<LoaderRound size={30} />
										</div>
									</BlurCard>
								) : (
									<>
										<BlurCard className='md:!p-4 !p-3 lg:mt-[40px] mt-[20px]'>
											{eventData?.opposing_team != null && eventData?.team != null ? (
												<div className='flex justify-between gap-6 w-full items-center pb-2'>
													<div className='flex justify-center items-center md:justify-between md:w-[130%] w-full gap-6'>
														<div className='md:w-[149px] !w-full md:pr-3 flex md:!justify-start !justify-center'>
															<TeamTitle title={get(eventData, 'team.name', '')} img={get(eventData, 'team.flag', '')} />
														</div>
														<div className='md:inline-flex justify-center hidden'>
															{/* <Title
                              title={time1 != "NaN:NaN" ? time1 : "VS"}
                              textsm={true}
                              color={`!text-green`}
                            /> */}
															<Title
																title={time1 != 'NaN:NaN' ? time1 : 'VS'}
																textsm={true}
																className={time1 != 'NaN:NaN' && time1.slice(-2) === '00' ? '!text-[13px]' : ''}
																color={`!text-green`}
															/>
														</div>
													</div>
													<div className='md:hidden inline '>
														<Title title={time1 != 'NaN:NaN' ? time1 : 'VS'} textsm={true} color={`!text-green`} />
													</div>
													<div className='flex md:!justify-start !justify-center items-center gap-6 md:ml-0 -ml-3 w-full'>
														<TeamTitle title={get(eventData, 'opposing_team.name', '')} img={get(eventData, 'opposing_team.flag', '')} />
													</div>
												</div>
											) : (
												''
											)}
											{/* ******** */}
											{eventData?.opposing_team_second != null && eventData?.team_second != null ? (
												<div
													className='flex justify-between gap-6 w-full items-center py-2'
													style={{
														borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
													}}
												>
													<div className='flex justify-center items-center md:justify-between md:w-[130%] w-full gap-6'>
														<div className='md:w-[149px] !w-full md:pr-3 flex md:justify-start justify-center'>
															<TeamTitle title={get(eventData, 'team_second.name', '')} img={get(eventData, 'team_second.flag', '')} />
														</div>
														<div className='md:inline-flex justify-center hidden'>
															<Title
																title={time2 != 'NaN:NaN' ? time2 : 'VS'}
																textsm={true}
																className={time2 != 'NaN:NaN' && time2.slice(-2) === '00' ? '!text-[13px]' : ''}
																color={`!text-green`}
															/>
														</div>
													</div>
													<div className='md:hidden inline '>
														<Title title={time2 != 'NaN:NaN' ? time2 : 'VS'} textsm={true} color={`!text-green`} />
													</div>
													<div className='flex md:!justify-start !justify-center items-center gap-6 w-full md:ml-0 -ml-3'>
														<div>
															<TeamTitle
																title={get(eventData, 'opposing_team_second.name', '')}
																img={get(eventData, 'opposing_team_second.flag', '')}
															/>
														</div>
													</div>
												</div>
											) : (
												''
											)}
											<div className='flex flex-col gap-3 mt-4'>
												<TeamInfo title={t('ticket_single.venue')} desc={get(eventData, 'arena.name', '')} />
												<TeamInfo title={t('ticket_single.date')} desc={<FormatDate time={eventData?.start_at} />} />
												{/* <TeamInfo
                        title={t("ticket_single.time")}
                        desc={<WeekTime time={eventData?.start_at} />}
                      /> */}
											</div>
										</BlurCard>
										{tab !== 3 && calcCount(my_places) ? (
											<div className='w-full flex justify-end'>
												<button
													className='mt-4 p-1 text-red-500  hover:text-red-700 font-normal text-sm'
													onClick={() => {
														dispatch({ type: 'CLEAR_MY_PLACES' });
													}}
												>
													{t('ticket_single.remove_all')}
												</button>
											</div>
										) : null}
										<div className='flex flex-col mt-2'>
											{my_places.map((sector, index) => (
												<div key={index}>
													{sector?.place?.map((place, pindex) => (
														<BlurCard className='md:!p-4 !p-3 mb-4' key={`${index}_${pindex}`}>
															<div className='flex gap-3'>
																<div
																	className='flex w-full flex-col gap-1.5'
																	style={{
																		borderRight: '1px solid rgba(255, 255, 255, 0.3)',
																	}}
																>
																	<p className='text-sm font-normal text-lightGray'>{t('ticket_single.section')}</p>
																	<CustomInput
																		placeholder=''
																		disabled
																		value={sector?.section_name}
																		className='!bg-transparent !border-0 !p-0 select-none'
																	/>
																</div>
																<div
																	className='flex w-full flex-col gap-1.5'
																	style={{
																		borderRight: '1px solid rgba(255, 255, 255, 0.3)',
																	}}
																>
																	<p className='text-sm font-normal text-lightGray'>{t('ticket_single.row')}</p>
																	<CustomInput
																		placeholder=''
																		disabled
																		value={place?.row_name}
																		className='!bg-transparent !border-0 !p-0 select-none'
																	/>
																</div>
																<div className='flex w-full'>
																	<div className='flex w-full flex-col gap-1.5'>
																		<p className='text-sm font-normal text-lightGray'>{t('ticket_single.seat')}</p>
																		<CustomInput
																			placeholder=''
																			disabled
																			value={place?.seat_name}
																			className='!bg-transparent !border-0 !p-0 select-none'
																		/>
																	</div>
																	{place?.parentt ? (
																		''
																	) : (
																		<>
																			{tab !== 3 ? (
																				<button
																					className='p-1 rounded bg-red-500 h-7 w-7 min-w-7 m-auto hover:bg-red-700 flex justify-center items-center'
																					onClick={() => {
																						setMyPlaces({
																							sector_id: sector?.section,
																							sector_name: sector?.section_name,
																							sector_category: sector?.sector_category,
																							seat: {
																								...place,
																								row_id: place?.row_id,
																								seat_id: place?.seat_id,
																							},
																						});

																						sector?.place.forEach(element => {
																							if (place?.seat_id === element?.parentt) {
																								setMyPlaces({
																									sector_id: sector?.section,
																									sector_name: sector?.section_name,
																									sector_category: sector?.sector_category,
																									seat: {
																										...element,
																										row_id: element?.row_id,
																										seat_id: element?.seat_id,
																									},
																								});
																							}
																						});
																					}}
																				>
																					<CloseIcon width={12} height={12} />
																				</button>
																			) : null}
																		</>
																	)}
																</div>
															</div>
														</BlurCard>
													))}
												</div>
											))}
										</div>
										{maxCount > calcCount(my_places) && !selectSector?.id ? (
											<div className='flex gap-2 items-center font-normal text-sm text-secondLight mb-4'>
												<img src='/images/exclamation.svg' alt='' /> <span>{t('ticket_single.rule')}</span>
											</div>
										) : null}
										{my_places?.length ? (
											<>
												{tab === 3 ? (
													''
												) : (
													<>
														<div className='mb-4'>
															{/* <InputUi
														type='select'
														label={t('ticket_single.currency')}
														name='currency'
														onChange={val => {
															setMyOrder({
																currency: val?.value,
															});
														}}
														value={currency_list.find(o => o.value === my_order.currency)}
														options={currency_list}
													/> */}
															<PaymentOptions
																options={currency_list}
																onChange={val => {
																	setMyOrder({
																		currency: val?.value,
																	});
																}}
																setMyOrder={setMyOrder}
															/>
														</div>
													</>
												)}
												{my_order.currency === 2 ? (
													<TeamInfo title={t('ticket_single.total')} desc={`$${calcTotalPrice(my_places).total_usd?.toFixed(2)}`} textLg={true} />
												) : (
													<TeamInfo
														title={t('ticket_single.total')}
														desc={`${PriceFormat(calcTotalPrice(my_places).total_uzs)} So'm`}
														textLg={true}
													/>
												)}
												{tab === 3 ? (
													''
												) : (
													<ButtonMain
														onClick={() => {
															setVerificationModal(true);
															window.scrollTo({
																top: 0,
																left: 0,
																behavior: 'smooth',
															});
															ga4_add_to_cart([
																{
																	item_id: get(eventData, 'id', ''),
																	item_name: get(eventData, 'name', ''),
																	coupon: get(eventData, 'slug', ''),
																	arena_id: get(eventData, 'arena.id', ''),
																	arena_name: get(eventData, 'arena.name', ''),
																	seats: my_places,
																},
															]);
														}}
														type='submit'
														type_color='t_transparent'
														text={t('others.continue')}
														className='w-full mt-6 !rounded-2xl'
														activeBtn={true}
													/>
												)}
											</>
										) : null}
									</>
								)}
							</BlurCard>
							{/* <div className='m-4 flex justify-center'>
								<LogoVisaWhite className='max-h-[100px]' />{' '}
							</div> */}
						</div>
					</div>
				</div>
			</div>
			{verificationModal ? (
				<div className='fixed top-0 left-0 w-full h-full z-[9999]'>
					<Verificationn setVerificationModal={setVerificationModal} tab={tab} setTab={setTab} ga4ViewCart={ga4ViewCart} />
				</div>
			) : (
				''
			)}
		</>
	);
};

export default TicketSingle;
