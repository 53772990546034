import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ArrowDownIcon from "../../svg/ArrowDownIcon";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const MatchTime = ({ arenaData, onSelectVenue }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedVenueName, setSelectedVenueName] = useState("");
  const [isHovered, setIsHovered] = useState(false); // state for hover
  const wrapperRef = useRef(null);
  const [selectedVenueId, setSelectedVenueId] = useState(null);

  useClickOutside(wrapperRef, () => {
    setIsOpen(false);
  });

  function useClickOutside(ref, onClickOutside) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          onClickOutside();
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, onClickOutside]);
  }

  const handleSelectVenue = (venueId, venueName) => {
    setIsOpen(false);
    setSelectedVenueName(venueName);
    onSelectVenue(venueId);
    setSelectedVenueId(venueId);
  };

  const shouldApplyHoverStyles = isHovered || isOpen; // combine hover and open state

  const getTranslatedVenueName = (venueId) => {
    switch (venueId) {
      case -1:
        return t("filter.all_matches");
      case 1:
        return t("filter.group_a");
      case 2:
        return t("filter.group_b");
      case 3:
        return t("filter.group_c");
      case 4:
        return t("filter.group_d");
      case 5:
        return t("filter.group_e");
      case 6:
        return t("filter.group_f");
      case 7:
        return t("filter.round_16");
      case 8:
        return t("filter.quarter_finals");
      case 9:
        return t("filter.semi_finals");
      case 10:
        return t("filter.third-place");
      case 11:
        return t("filter.final");
      default:
        return "";
    }
  };

  // Update the selected venue name when the language changes
  useEffect(() => {
    const handleLanguageChange = () => {
      if (selectedVenueId !== null) {
        setSelectedVenueName(getTranslatedVenueName(selectedVenueId));
      }
    };
    i18next.on("languageChanged", handleLanguageChange);

    // Cleanup function
    return () => {
      i18next.off("languageChanged", handleLanguageChange);
    };
  }, [selectedVenueId]);

  return (
    <div ref={wrapperRef} className="relative w-full">
      <button
        className={`relative w-full overflow-hidden px-4 py-2.5 rounded-2xl border 
    border-secondaryGray hover:bg-blue transition-all ease-in-out duration-200 hover:border-transparent
    ${isOpen ? "bg-blue border-transparent" : ""}`}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        onMouseEnter={() => setIsHovered(true)} // set hover state on enter
        onMouseLeave={() => setIsHovered(false)} // unset hover state on leave
      >
        <div className=" relative z-10 flex items-center gap-2 justify-between text-lightGray h-[48px]">
          <div
            className={`flex items-center transition-all ease-in-out duration-200 ${
              selectedVenueName !== "" ? "flex" : ""
            }`}
          >
            <span className="mr-4">
              <img
                src="/images/football-pitch.svg"
                alt=""
                className={`${shouldApplyHoverStyles ? "hovered-img" : ""}`} // apply hover class
              />
            </span>
            <div className="flex flex-col items-start">
              <span
                className={`${
                  selectedVenueName !== "" ? "relative top-0" : ""
                } ${shouldApplyHoverStyles ? "hovered-text" : ""}`}
              >
                {t("filter.match_type")}
              </span>
              <span
                className={`text-secondLight ${
                  shouldApplyHoverStyles ? "hovered-text" : ""
                }`}
              >
                {selectedVenueName}
              </span>
            </div>
          </div>
          {isOpen ? (
            <ArrowDownIcon className="rotate-180" />
          ) : (
            <ArrowDownIcon color="#646A83" />
          )}
        </div>
        <span className="absolute top-0 left-0 z-[1] w-full h-full backdrop-blur-lg bg-blue-500/10" />
      </button>

      <div className="w-full ">
        {isOpen && (
          <div
            className={`shadow-[#ffffff50] shadow-md mt-4 absolute duration-200 transition-all ease-in-out top-[55px] right-0 h-auto bg-deepCove rounded-2xl z-[20]  w-full ${
              isOpen ? "box-shadow-open" : ""
            } `}
            style={{
              boxShadow: isOpen ? "0px 2px 16px 0px rgba(0, 0, 0, 0.12)" : "",
            }}
          >
            <div className="shadow-[#ffffff50] shadow-lg flex flex-col max-h-[400px] overflow-y-auto md:!overflow-y-visible overflow-x-hidden md:!max-h-auto w-full rounded-2xl">
              <Link
                to="#"
                className="text-secondLight py-2 font-normal text-base text-center duration-200 transition-all ease-in-out hover:bg-blue w-full"
                onClick={() => handleSelectVenue(-1, t("filter.all_matches"))}
              >
                {t("filter.all_matches")}
              </Link>
              <Link
                to="#"
                className="w-full text-secondLight py-2 font-normal text-base text-center duration-200 transition-all ease-in-out hover:bg-blue"
                onClick={() => handleSelectVenue(1, t("filter.group_a"))}
              >
                {t("filter.group_a")}
              </Link>
              <Link
                to="#"
                className="w-full text-secondLight py-2 font-normal text-base text-center duration-200 transition-all ease-in-out hover:bg-blue"
                onClick={() => handleSelectVenue(2, t("filter.group_b"))}
              >
                {t("filter.group_b")}
              </Link>
              <Link
                to="#"
                className="w-full text-secondLight py-2 font-normal text-base text-center duration-200 transition-all ease-in-out hover:bg-blue"
                onClick={() => handleSelectVenue(3, t("filter.group_c"))}
              >
                {t("filter.group_c")}
              </Link>
              <Link
                to="#"
                className="w-full text-secondLight py-2 font-normal text-base text-center duration-200 transition-all ease-in-out hover:bg-blue"
                onClick={() => handleSelectVenue(4, t("filter.group_d"))}
              >
                {t("filter.group_d")}
              </Link>
              <Link
                to="#"
                className="w-full text-secondLight py-2 font-normal text-base text-center duration-200 transition-all ease-in-out hover:bg-blue"
                onClick={() => handleSelectVenue(5, t("filter.group_e"))}
              >
                {t("filter.group_e")}
              </Link>
              <Link
                to="#"
                className="w-full text-secondLight py-2 font-normal text-base text-center duration-200 transition-all ease-in-out hover:bg-blue"
                onClick={() => handleSelectVenue(6, t("filter.group_f"))}
              >
                {t("filter.group_f")}
              </Link>
              <Link
                to="#"
                className="w-full text-secondLight py-2 font-normal text-base text-center duration-200 transition-all ease-in-out hover:bg-blue"
                onClick={() => handleSelectVenue(7, t("filter.round_16"))}
              >
                {t("filter.round_16")}
              </Link>
              <Link
                to="#"
                className="w-full text-secondLight py-2 font-normal text-base text-center duration-200 transition-all ease-in-out hover:bg-blue"
                onClick={() => handleSelectVenue(8, t("filter.quarter_finals"))}
              >
                {t("filter.quarter_finals")}
              </Link>
              <Link
                to="#"
                className="w-full text-secondLight py-2 font-normal text-base text-center duration-200 transition-all ease-in-out hover:bg-blue"
                onClick={() => handleSelectVenue(9, t("filter.semi_finals"))}
              >
                {t("filter.semi_finals")}
              </Link>
              <Link
                to="#"
                className="w-full text-secondLight py-2 font-normal text-base text-center duration-200 transition-all ease-in-out hover:bg-blue"
                onClick={() => handleSelectVenue(10, t("filter.third-place"))}
              >
                {t("filter.third-place")}
              </Link>
              <Link
                to="#"
                className="w-full text-secondLight py-2 font-normal text-base text-center duration-200 transition-all ease-in-out hover:bg-blue"
                onClick={() => handleSelectVenue(11, t("filter.final"))}
              >
                {t("filter.final")}
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MatchTime;
