import React, { useEffect } from "react";
import LoaderRound from "../../../../../components/ui/LoaderRound";
import { ga4_purchase } from "../../../../../utils/ga4_actions/actions";
import { useNavigate, useParams } from "react-router-dom";
import Axios from "../../../../../utils/httpClient";

const Success = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    // console.log("id=", id);
    checkOrder(id);
  }, []);
  const checkOrder = (o_id) => {
    Axios()
      .get("orders/check_order_is_accept/" + o_id)
      .then((r) => {
        if (r?.data?.accept === 1) {
          ga4Purchase(o_id);
        }
      })
      .catch((e) => {})
      .finally(() => {
        navigate("/");
      });
  };
  const ga4Purchase = (o_id) => {
    ga4_purchase([
      {
        item_id: o_id,
        order_id: o_id,
        // item_name: get(eventData, "name", ""),
        // coupon: get(eventData, "slug", ""),
        // arena_id: get(eventData, "arena.id", ""),
        // arena_name: get(eventData, "arena.name", ""),
        // seats: my_places,
      },
    ]);
  };
  return (
    <div className="pt-[150px] flex justify-center">
      <LoaderRound size={50} />
    </div>
  );
};

export default Success;
